import * as SentryLib from '@sentry/react'
import { Integrations } from '@sentry/tracing'

import Config from 'Config'
import { version } from '../../package.json'

export const Sentry = SentryLib
export const initSentry = () => {
  Sentry.init({
    dsn: process.env.NODE_ENV === 'development' ? Config.sentryUrl : '',
    integrations: [ new Integrations.BrowserTracing() ],
    tracesSampleRate: 1.0,
    release: version,
    environment: process.env.NODE_ENV
  })
}
