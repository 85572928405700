import BaseService from './BaseService'
import { httpClient } from '@/api/httpClient'
import { ReviewQuestion } from '@/models/entity/reviewQuestion/ReviewQuestion'

export default class ReviewQuestionService extends BaseService {
  static async create (data: ReviewQuestion) {
    return httpClient.post('/protected/review_questions', data)
  }

  static async load () {
    const { data } = await httpClient.get<ReviewQuestion[]>('/protected/review_questions')

    return data
  }
}
