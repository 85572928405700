import React from 'react'

export default class CustomAlertTemplate extends React.Component {
  render () {
    return (
      <div
        id={this.props.id}
        className={this.props.classNames}
        style={this.props.styles}
        onClick={this.props.handleClose}
      >
        <div className='s-alert-box-inner'>{this.props.message}</div>
        <span>{this.props.customFields.customerName}</span>
      </div>
    )
  }
}
