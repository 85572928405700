import { getPercentage } from '@/utils/mathUtils'

export const mapDtoToBulkSummary = (dto) => {
  const sent = dto.message_sent_count || 0
  const inQueue = dto.message_pending_count || 0
  const notDelivered = dto.message_not_sent_count || 0
  const read = dto.message_read_count || 0
  const notRead = dto.message_not_read_count || 0
  const used = dto.bulk_used_count || 0
  const notUsed = dto.bulk_not_used_count || 0

  return {
    startedAt: dto.created_at && new Date(dto.created_at),
    id: dto.id,
    bulkNotUsedCount: dto.bulk_not_used_count || 0,
    bulkUsedCount: dto.bulk_used_count || 0,
    messageNotReadCount: dto.message_not_read_count || 0,
    messageReadCount: dto.message_read_count || 0,
    name: dto.name,
    orderAmountAverage: dto.order_amount_average,
    orderAmountTotal: dto.order_amount_total,
    status: dto.status,
    subscribersCount: dto.subscribers_count || 0,
    sent,
    sentPercent: getPercentage(sent, dto.subscribers_count),
    inQueue,
    inQueuePercent: getPercentage(inQueue, dto.subscribers_count),
    notDelivered,
    notDeliveredPercent: getPercentage(notDelivered, dto.subscribers_count),
    read,
    readPercent: getPercentage(read, dto.subscribers_count),
    notRead,
    notReadPercent: getPercentage(notRead, dto.subscribers_count),
    used,
    usedPercent: getPercentage(used, dto.subscribers_count),
    notUsed: notUsed < 0 ? 0 : notUsed,
    notUsedPercent: getPercentage(notUsed, dto.subscribers_count),
    totalSum: parseFloat(dto.order_amount_total),
    avgSum: parseFloat(dto.order_amount_average)
  }
}

export const mapDtoToBulkSubscriber = (dto) => {
  return {
    id: dto.id,
    date: dto.created_at,
    avatar: dto.subscriber && dto.subscriber.avatar,
    fullName: dto.subscriber && dto.subscriber.full_name,
    phone: dto.subscriber && dto.subscriber.phone,
    status: dto.message_status,
    read: dto.read,
    used: dto.used,
    order: dto.order && {
      date: dto.order.iiko_created_at,
      number: dto.order.iiko_order_number,
      sum: dto.order.amount
    }
  }
}
