import { AxiosResponse } from 'axios'
import { PromotionDeliveryPayload, PromotionDeliveryStoreType } from '.'
import BaseService from '../BaseService'
import { httpClient } from '@/api/httpClient'
import { DeliveryPromotionDto } from '@/models/entity/promotions/delivery/DeliveryPromotion'
import { mapDtoToDeliveryPromotion } from '@/utils/mappers/promotion-mappers'

export default class PromotionsDeliveryService extends BaseService {
  static async getDeliveryStores () {
    const { data } = await httpClient.get<never, AxiosResponse<PromotionDeliveryStoreType[]>>('/protected/delivery/stores')

    return data
  }

  static async createPromotionDelivery (payload: PromotionDeliveryPayload) {
    return httpClient.post('/protected/promotions/delivery', payload)
  }

  static async editPromotionDelivery (promotionId: string, payload: PromotionDeliveryPayload) {
    return httpClient.patch(`/protected/promotions/delivery/${promotionId}`, payload)
  }

  static async deletePromotionDelivery (promotionId: string) {
    return httpClient.delete(`/protected/promotions/delivery/${promotionId}`)
  }

  static async getPromotionsDelivery (payload: { page: number, per_page: number }) {
    const filters = new URLSearchParams()
    filters.set('page', payload.page.toString())
    filters.set('per_page', payload.per_page.toString())

    const data = await httpClient.get<never, AxiosResponse<DeliveryPromotionDto[]>>(`/protected/promotions/delivery?${filters}`)

    return { data: data.data.map((item) => mapDtoToDeliveryPromotion(item)), headers: data.headers }
  }
}
