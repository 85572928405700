import React, { Component } from 'react'
import { Dropdown } from 'semantic-ui-react'
import spacetime from 'spacetime/builds/spacetime'

const i18n = {
  'Pacific/Midway': 'Остров Мидуэй, Самоа',
  'Pacific/Honolulu': 'Гавайи',
  'America/Juneau': 'Аляска',
  'America/Dawson': 'Тихоокеанское время (США и Канада); Тихуана',
  'America/Boise': 'Горное время (США и Канада)',
  'America/Chihuahua': 'Чихуахуа, Ла-Пас, Масатлан',
  'America/Phoenix': 'Аризона',
  'America/Chicago': 'Центральное время (США и Канада)',
  'America/Regina': 'Саскачеван',
  'America/Mexico_City': 'Гвадалахара, Мехико, Монтеррей',
  'America/Belize': 'Центральная Америка',
  'America/Detroit': 'Восточное время (США и Канада)',
  'America/Indiana/Indianapolis': 'Индиана (восток)',
  'America/Bogota': 'Богота, Лима, Кито',
  'America/Glace_Bay': 'Атлантическое время (Канада)',
  'America/Caracas': 'Каракас, Ла-Пас',
  'America/Santiago': 'Сантьяго',
  'America/St_Johns': 'Ньюфаундленд и Лабрадор',
  'America/Sao_Paulo': 'Бразилиа',
  'America/Argentina/Buenos_Aires': 'Буэнос-Айрес, Джорджтаун',
  'America/Godthab': 'Гренландия',
  'Etc/GMT+2': 'Средняя Атлантика',
  'Atlantic/Azores': 'Азорские острова',
  'Atlantic/Cape_Verde': 'Острова Зеленого Мыса',
  GMT: 'Дублин, Эдинбург, Лиссабон, Лондон',
  'Africa/Casablanca': 'Касабланка, Монровия',
  'Atlantic/Canary': 'Канарские острова',
  'Europe/Belgrade': 'Белград, Братислава, Будапешт, Любляна, Прага',
  'Europe/Sarajevo': 'Сараево, Скопье, Варшава, Загреб',
  'Europe/Brussels': 'Брюссель, Копенгаген, Мадрид, Париж',
  'Europe/Amsterdam': 'Амстердам, Берлин, Берн, Рим, Стокгольм, Вена',
  'Africa/Algiers': 'Западная Центральная Африка',
  'Europe/Bucharest': 'Бухарест',
  'Africa/Cairo': 'Каир',
  'Europe/Helsinki': 'Хельсинки, Киев, Рига, София, Таллинн, Вильнюс',
  'Europe/Athens': 'Афины, Стамбул, Минск',
  'Asia/Jerusalem': 'Иерусалим',
  'Africa/Harare': 'Хараре, Претория',
  'Europe/Moscow': 'Москва, Санкт-Петербург, Волгоград',
  'Asia/Kuwait': 'Кувейт, Эр-Рияд',
  'Africa/Nairobi': 'Найроби',
  'Asia/Baghdad': 'Багдад',
  'Asia/Tehran': 'Тегеран',
  'Asia/Dubai': 'Абу-Даби, Маскат',
  'Asia/Baku': 'Баку, Тбилиси, Ереван',
  'Asia/Kabul': 'Кабул',
  'Asia/Yekaterinburg': 'Екатеринбург',
  'Asia/Karachi': 'Исламабад, Карачи, Ташкент',
  'Asia/Kolkata': 'Ченнаи, Калькутта, Мумбаи, Нью-Дели',
  'Asia/Kathmandu': 'Катманду',
  'Asia/Dhaka': 'Астана, Дакка',
  'Asia/Colombo': 'Шри Джаяварденепура',
  'Asia/Almaty': 'Алматы',
  'Asia/Rangoon': 'Янгон Рангун',
  'Asia/Bangkok': 'Бангкок, Ханой, Джакарта',
  'Asia/Krasnoyarsk': 'Красноярск',
  'Asia/Novosibirsk': 'Новосибирск',
  'Asia/Shanghai': 'Пекин, Чунцин, САР Гонконг, Урумчи',
  'Asia/Kuala_Lumpur': 'Куала-Лумпур, Сингапур',
  'Asia/Taipei': 'Тайбэй',
  'Australia/Perth': 'Перт',
  'Asia/Irkutsk': 'Иркутск, Улан-Батор',
  'Asia/Seoul': 'Сеул',
  'Asia/Tokyo': 'Осака, Саппоро, Токио',
  'Asia/Yakutsk': 'Якутск',
  'Australia/Darwin': 'Дарвин',
  'Australia/Adelaide': 'Аделаида',
  'Australia/Sydney': 'Канберра, Мельбурн, Сидней',
  'Australia/Brisbane': 'Брисбен',
  'Australia/Hobart': 'Хобарт',
  'Asia/Vladivostok': 'Владивосток',
  'Pacific/Guam': 'Гуам, Порт-Морсби',
  'Asia/Magadan': 'Магадан, Соломоновы острова, Новая Каледония',
  'Pacific/Fiji': 'Острова Фиджи, Камчатка, Маршалловы острова',
  'Pacific/Auckland': 'Окленд, Веллингтон',
  'Pacific/Tongatapu': 'Нукуалофа'
}

export default class SelectTimeZones extends Component {
  static defaultProps = {
    onChange: () => {},
    onBlur: () => {},
    value: null
  }

  state = {
    options: [],
    selectedValue: null
  }

  componentDidMount () {
    const options = []

    Object.entries(i18n)
      .reduce((obj, entry) => {
        const a = spacetime.now().goto(entry[0])
        const tz = a.timezone()

        obj.push({
          name: entry[0],
          label: entry[1],
          offset: tz.current.offset
        })

        return obj
      })
      .sort((a, b) => a.offset - b.offset)
      .forEach(tz => {
        if (tz.offset === undefined) return false

        const min = tz.offset * 60
        const hr = `${(min / 60) ^ 0}:` + (min % 60 === 0 ? '00' : min % 60)

        options.push({
          value: tz.name,
          text: `(GMT${hr.includes('-') ? hr : `+${hr}`}) ${tz.label}`
        })
      })

    this.setState({ options, selectedValue: this.props.value })
  }

  handleInputChange = (event, { name, value }) => {
    this.setState({
      [name]: value
    })

    this.props.onChange(value)
  }

  render () {
    const { options, selectedValue } = this.state

    return (
      <Dropdown
        placeholder='Выберите часовой пояс'
        fluid
        search
        selection
        value={selectedValue}
        name='selectedValue'
        onChange={this.handleInputChange}
        options={options}
        onBlur={() => this.props.onBlur}
      />
    )
  }
}
