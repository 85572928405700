import plural from 'plural-ru'

export function daysPluralFormat (daysAmount) {
  if (!daysAmount) return ''

  return plural(daysAmount, ' день', ' дня', ' дней')
}

export function hoursPluralFormat (hoursAmount) {
  if (!hoursAmount) return ''

  return plural(hoursAmount, ' час', ' часа', ' часов')
}

export function subscribersPluralFormat (amount) {
  if (!amount) return ''

  return plural(amount, ' подписчик', ' подписчика', ' подписчиков')
}

/**
 * Convert string from snake_case to camelCase.
 * @param str snake_case string.
 * @returns camelCase string.
 */
export function camelize (str) {
  return str
    .split('_')
    .map((word, i) => (i === 0 ? word : word[0].toUpperCase() + word.slice(1)))
    .join('')
}
