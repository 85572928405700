import React, { Component } from 'react'
import { Loader, Segment, Dimmer } from 'semantic-ui-react'
import { SemanticSIZES } from 'semantic-ui-react/dist/commonjs/generic'

import './Loading.scss'

type Props = {
  text?: string | null,
  size?: SemanticSIZES,
  padded?: boolean | 'very',
  centered?: boolean,
  withoutDimmer?: boolean,
  withoutText?: boolean,
  inverted?: boolean,
  className?: string,
  transparent?: boolean,
  messageId?: string
}

export default class Loading extends Component<Props> {
  render () {
    const {
      text = null,
      size = 'large',
      padded = 'very',
      centered = true,
      withoutDimmer = false,
      withoutText = false,
      inverted = true,
      className = 'loader-bg',
      transparent = false
    } = this.props

    // Segment and dimmer is required because of bugged modals
    let loadingText = null

    if (!withoutText) {
      loadingText = text || 'Загрузка...'
    }
    let renderedContent = null

    if (!withoutDimmer) {
      renderedContent = (
        <Segment basic padded={padded}>
          <Dimmer active inverted={inverted} className={transparent ? className : ''}>
            <Loader active size={size} content={loadingText} />
          </Dimmer>
        </Segment>
      )
    } else {
      renderedContent = (
        <Segment basic padded={padded}>
          <Loader active size={size} content={loadingText} />
        </Segment>
      )
    }

    return centered ? (<div className={`loading-wrapper ${className}`}>{renderedContent}</div>) : renderedContent
  }
}
