import React, { Component } from 'react'
import { Button, Icon } from 'semantic-ui-react'
import { SemanticSIZES, SemanticWIDTHS } from 'semantic-ui-react/dist/commonjs/generic'

import './ButtonsSelect.scss'

type Props = {
  value: string | null,
  options: {
    key?: any,
    text: string,
    value: string,
    className?: string,
    icon?: string,
    color?: string,
    activeColor?: string
  }[],
  groupProps: {
    widths?: SemanticWIDTHS,
    size?: SemanticSIZES,
    compact?: boolean,
    fluid?: boolean,
    attached?: 'top' | 'left' | 'right' | 'bottom' | undefined
  } | undefined,
  onChange: (value: string) => void
  name?: string,
  errorClassName?: string | false
}

export default class ButtonsSelect extends Component<Props> {
  render () {
    const { value = '', options = [], groupProps = undefined, onChange = () => {}, errorClassName } = this.props

    return (
      <Button.Group className={`buttons-select ${errorClassName}`} {...groupProps}>
        {
          options.map(option => {
            const isActiveBtn = value === option.value
            const activeColor = isActiveBtn ? 'green' : undefined

            return (
              <Button
                type="button"
                key={option.value}
                className={`buttons-select__button ${option.className || ''}`}
                active={isActiveBtn}
                icon={option.icon}
                color={activeColor}
                content={option.text}
                onClick={onChange.bind(this, option.value)}
              />
            )
          })
        }
      </Button.Group>
    )
  }
}
