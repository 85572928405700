import { ValueTypes as PromotionStatusValues } from '@/constants/promotion/promotion-base-statuses'

type Image = {
  full: string;
  id: number;
  logo: string;
  social: string;
  thumb: string;
}

export type DeliveryPromotionDto = {
  background_image: Image
  background_image_app: Image
  background_image_banner: Image
  created_at: string
  ended_at: string | null
  delivery_stores: {id: string, url: string }[]
  description: string
  id: string
  indefinite: boolean
  menu_iiko_code: string
  name: string
  position: number | null
  started_at: string
  status: PromotionStatusValues | null
  updated_at: string
  url: string
}

export default class DeliveryPromotion {
  public backgroundImage: Image | null
  public backgroundImageApp: Image | null
  public backgroundImageBanner: Image | null
  public createdAt: string
  public endedAt: string | null
  public deliveryStores: {id: string, url: string }[]
  public description: string
  public id: string
  public indefinite: boolean
  public menuIikoCode: string
  public name: string
  public position: number | null
  public startedAt: string
  public status: PromotionStatusValues | null
  public updatedAt: string
  public url: string

  public constructor (data: Partial<DeliveryPromotion>) {
    this.id = data.id!
    this.name = data.name ?? ''
    this.createdAt = data.createdAt ?? ''
    this.description = data.description ?? ''
    this.menuIikoCode = data.menuIikoCode ?? ''
    this.position = data.position ?? null
    this.endedAt = data.endedAt ?? ''
    this.indefinite = data.indefinite ?? false
    this.deliveryStores = data.deliveryStores ?? []
    this.backgroundImage = data.backgroundImage ?? null
    this.backgroundImageApp = data.backgroundImageApp ?? null
    this.backgroundImageBanner = data.backgroundImageBanner ?? null
    this.url = data.url ?? ''
    this.startedAt = data.startedAt ?? ''
    this.status = data.status ?? null
    this.updatedAt = data.updatedAt ?? ''
  }
}
