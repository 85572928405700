import React, { PureComponent, ReactElement } from 'react'
import { Modal, Button, Icon, Image, Label } from 'semantic-ui-react'
import Dropzone, { FileRejection } from 'react-dropzone'
import Cropper from 'react-cropper'

import 'cropperjs/dist/cropper.css'
import './ImageCropper.scss'
import UploadImage from '../../images/cloud-upload.png'
import { UploadImage as UploadImageType } from '../../models/entity/uploadImage/UploadImage'

import ConditionalHidden from '../ConditionalHidden'
import { Loading } from '..'
import AppModal from '../AppModal'
import { NotificationsService } from '@/services'

interface IFile extends Blob {
  path?: string,
  lastModified: number,
  lastModifiedDate?: Date
  name: string,
  size: number,
  type: string
  webkitRelativePath: string
}

type Props = {
  image: UploadImageType | null | string,
  aspectRatio?: number,
  baseWidth?: number,
  saveOriginal?: boolean,
  withIcon: boolean,
  withText: boolean,
  mini?: boolean,
  initialImage?: any,
  showRemoveIcon?: boolean,
  imageCamoified: UploadImageType | null | string,
  original?: any,
  onChange: (pictureFiles: string | null) => Promise<null | void>,
  originalAspectRatio: boolean,
  withoutCompression: boolean,
  showCropInModal: boolean,
  size?: number
  maxSizeInBytes?: number
}

type State = {
  fullImage: string | UploadImageType | null,
  rotate: number,
  scale: number,
  loading: boolean,
  uploading: boolean,
  uploaded: boolean,
  croppedImageUploadedPath: UploadImageType | string | null,
  originalImageUploadedPath: boolean,
  croppedImage: null | string | UploadImageType | undefined,
  originalImage: null | string,
  isSvg: boolean,
  hasBeenSetInitially: boolean,
  modalIsShow: boolean,
  processing: boolean,
}

class ImageCropper extends PureComponent<Props, State> {
  state = {
    fullImage: null,
    rotate: 0,
    scale: 1,
    loading: false,
    uploading: false,
    uploaded: false,
    croppedImageUploadedPath: null,
    originalImageUploadedPath: false,
    croppedImage: null,
    originalImage: null,
    isSvg: false,
    hasBeenSetInitially: false,
    modalIsShow: false,
    processing: false
  }

  static defaultProps = {
    image: null,
    aspectRatio: 1,
    baseWidth: 600,
    saveOriginal: false,
    withIcon: false,
    withText: true,
    mini: false,
    initialImage: null,
    showRemoveIcon: true,
    imageProcessing: false
  }

  private cropPingedOnce: boolean
  private editor: Cropper | null

  constructor (props: Props) {
    super(props)

    this.cropPingedOnce = !this.state.fullImage
    this.editor = null
  }

  componentDidMount () {
    this.setImageDataInitially()
  }

  componentDidUpdate (prevProps: Props) {
    if (!this.state.hasBeenSetInitially && (prevProps.initialImage !== this.props.initialImage)) {
      this.setState({ hasBeenSetInitially: true }, this.setImageDataInitially)
    }
  }

  setImageDataInitially = () => {
    if (this.props.image) {
      this.setState({
        fullImage: this.props.imageCamoified,
        modalIsShow: false,
        uploaded: true,
        croppedImageUploadedPath: this.props.image,
        originalImageUploadedPath: this.props.original,
        croppedImage: this.props.image,
        originalImage: this.props.original
      })
    }
  }

  onDropAccepted (file: IFile[]) {
    this.setState({ loading: true, modalIsShow: true })

    const reader = new FileReader()

    reader.onload = (event) => {
      const image: any = event.target?.result

      if (image?.startsWith('data:image/svg')) {
        this.setState({ fullImage: image, loading: false, croppedImage: image, originalImage: image, isSvg: true })
        this.pingOnChange(image)
      } else {
        this.setState({ fullImage: image, loading: false, croppedImage: null, originalImage: null, isSvg: false })
        this.pingOnChange()
      }
    }

    reader.readAsDataURL(file[0])
  }

  onSizeLimit = (files: FileRejection[]) => {
    files.forEach(file => {
      file.errors.forEach(fileError => {
        if (fileError.code === 'file-too-large') {
          NotificationsService.error(`Размер картинки не должен превышать ${this.props.maxSizeInBytes ? (this.props.maxSizeInBytes / 1024) : 230} Кб`)
        }
      })
    })
  }

  async pingOnChange (croppedImage: string | null = null) {
    if (this.props.onChange) await this.props.onChange(croppedImage)
    this.setState({ uploaded: false, croppedImageUploadedPath: null, originalImageUploadedPath: false })
  }

  onCrop () {
    // Зачем это надо?
    // Кроппер вызывает onCrop при первой загрузке картинки
    if (!this.cropPingedOnce) this.cropPingedOnce = true
  }
  getCroppedImage () {
    type Options = {
      imageSmoothingEnabled: boolean,
      imageSmoothingQuality: 'high' | 'low' | 'medium',
      maxWidth: number,
      maxHeight: number,
      width?: number,
      height?: number
    }
    const options: Options = {
      imageSmoothingEnabled: true,
      imageSmoothingQuality: 'high',
      maxWidth: 2000,
      maxHeight: 2000
    }

    if (!this.props.originalAspectRatio && this.props.baseWidth && this.props.aspectRatio) {
      options.width = this.props.baseWidth
      options.height = this.props.baseWidth * this.props.aspectRatio
    }

    const croppedImage = this.editor?.getCroppedCanvas(options)

    if (this.props.withoutCompression && croppedImage) {
      return croppedImage.toDataURL('image/png')
    }

    return croppedImage && croppedImage.toDataURL('image/jpeg', 0.92)
  }

  async acceptCrop () {
    const croppedImage = this.getCroppedImage()
    const originalImage = this.state.fullImage

    try {
      this.setState({ processing: true })
      await this.pingOnChange(croppedImage)
      this.setState({ croppedImage, originalImage, modalIsShow: false })
    } finally {
      this.setState({ processing: false })
    }
  }

  rotateLeft () {
    this.setState({ rotate: this.state.rotate - 45 })
  }

  rotateRight () {
    this.setState({ rotate: this.state.rotate + 45 })
  }

  zoomIn () {
    this.editor?.zoom(0.1)
  }

  zoomOut () {
    this.editor?.zoom(-0.1)
  }

  clearImage () {
    this.setState({ fullImage: null, scale: 1, rotate: 0, modalIsShow: false, croppedImage: this.props.image || null, originalImage: this.props.original || null })
    this.pingOnChange()
  }

  removeImage () {
    this.setState({ fullImage: null, scale: 1, rotate: 0, croppedImage: null, originalImage: null })
    this.pingOnChange()
  }

  getRenderedDropzone () {
    if (this.state.croppedImage) {
      return (
        <div className='cropper-dropzone cropper-dropzone_with-img'>
          <Image className='cropper-dropzone__image' src={this.state.croppedImage} alt='cropped img'/>

          {this.props.showRemoveIcon && (
            <Label attached='top right' className='cropper-dropzone__image-label' onClick={this.removeImage.bind(this)}>
              <Icon circular inverted name='delete' />
            </Label>
          )}
        </div>
      )
    }

    let uploadIcon: ReactElement | null = null
    let uploadText: ReactElement | null = null

    if (this.props.withIcon) {
      uploadIcon = <img className='cropper-dropzone__image cropper-dropzone__upload-icon' src={UploadImage} alt='Upload cloud'/>
    }

    if (this.props.withText) {
      uploadText = (
        <p className='cropper-dropzone__text'>
          Перетащите изображение в это поле или нажмите для выбора
        </p>
      )
    }

    return (
      <Dropzone
        multiple={false}
        accept='image/*'
        onDropAccepted={this.onDropAccepted.bind(this)}
        onDropRejected={(this.props.maxSizeInBytes && this.onSizeLimit) || undefined}
        maxSize={this.props.maxSizeInBytes}
      >
        {({ getRootProps, getInputProps }) => (
          <div {...getRootProps()} className="cropper-dropzone cropper-dropzone_without-img">
            <input {...getInputProps()} />
            {uploadIcon}
            {uploadText}
          </div>
        )}
      </Dropzone>
    )
  }

  getRenderedCropper (cropperComponent: React.ReactNode) {
    if (this.props.showCropInModal && !this.state.isSvg) {
      return (
        <AppModal shouldConfirmClose onClose={this.clearImage.bind(this)} open={this.state.modalIsShow}>
          <Modal.Header>Выберите область</Modal.Header>
          <Modal.Content>
            <Modal.Description>
              {cropperComponent}

              <ConditionalHidden hidden={!this.state.loading}>
                <Loading messageId='component.loading.uploadingImage' />
              </ConditionalHidden>
            </Modal.Description>
          </Modal.Content>

          <Modal.Actions>
            <Button onClick={this.clearImage.bind(this)} content="Отменить" />
            <Button
              color='green'
              icon
              onClick={this.acceptCrop.bind(this)}
              className="labeled left"
              loading={this.state.processing}
              disabled={this.state.processing}
            >
              <i className="icon lnr lnr-crop" style={{ color: 'white' }}></i>
              Обрезать
            </Button>
          </Modal.Actions>
        </AppModal>
      )
    }

    return cropperComponent
  }

  render () {
    const squareImageClass = (() => {
      if (this.props.originalAspectRatio) {
        return 'cropper-dropzone-wrapper_original'
      }
      switch (this.props.aspectRatio) {
      case 1.5:
        return 'cropper-dropzone-wrapper_3x2'
      case 2:
        return 'cropper-dropzone-wrapper_2x1'
      case 6:
        return 'cropper-dropzone-wrapper_6x1'
      default:
        return 'cropper-dropzone-wrapper_1x1'
      }
    })()
    let cropperComponent = null
    let cancelButton = null
    let drop: ReactElement | null = this.getRenderedDropzone()

    if (this.state.fullImage && !this.state.croppedImage) {
      if (!this.props.showCropInModal) {
        drop = null
        cancelButton = <Button icon color='red' onClick={this.clearImage.bind(this)}><Icon name='cancel' /></Button>
      }
      cropperComponent = (
        <div>
          <ConditionalHidden hidden={this.state.uploading}>
            <div className='cropper-wrapper'>
              <Cropper
                className='cropper-itself'
                aspectRatio={!this.props.originalAspectRatio ? this.props.aspectRatio : 0}
                src={this.state.fullImage}
                rotateTo={this.state.rotate}
                crop={this.onCrop.bind(this)}
                responsive
                guides
                minContainerWidth={240}
                minContainerHeight={320 /* Change this value in CSS too. Fix for wrong size if hidden */}
                viewMode={2}
                autoCropArea={1}
                onInitialized={(editor: Cropper) => {
                  this.editor = editor
                }}
              />

              <div className='cropper-controls'>
                <Button.Group>
                  <Button icon onClick={this.zoomIn.bind(this)}><Icon name='plus' /></Button>
                  <Button icon onClick={this.zoomOut.bind(this)}><Icon name='minus' /></Button>
                </Button.Group>
                {' '}
                <Button.Group>
                  <Button icon onClick={this.rotateLeft.bind(this)}><Icon name='undo' /></Button>
                  <Button icon onClick={this.rotateRight.bind(this)}><Icon name='repeat' /></Button>
                </Button.Group>
                {' '}
                {cancelButton}
              </div>
            </div>
          </ConditionalHidden>

          <ConditionalHidden hidden={!this.state.uploading}>
            <Loading messageId='component.loading.uploadingImage' />
          </ConditionalHidden>
        </div>
      )
    }
    const cropper = this.getRenderedCropper(cropperComponent)

    return (
      <div>
        <div className={`cropper-dropzone-wrapper ${squareImageClass} ${this.props.size}`}>
          {drop}
        </div>
        {cropper}
      </div>
    )
  }
}

export default ImageCropper
