export default {
  webview: {
    text: 'Web форма',
    color: 'blue'
  },
  chatbot: {
    text: 'Чат-бот',
    color: 'purple'
  },
  app: {
    text: 'Прил.',
    color: 'violet'
  },
  operator: {
    text: 'Оператор',
    color: 'pink'
  }
}
