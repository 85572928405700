import axios from 'axios'

import { UserService } from '@/services'
import { GatewayTimeout, NetworkError, ServerError, ServerUnavailable, ServerValidationError, InternalServerError, NotFoundError, ForbiddenError } from './exceptions'
import { Sentry } from '@/plugins/sentry'

/**
 * Set Authorization header from storage.
 */
export function requestInterceptor (config) {
  if (UserService.isAuthenticated) {
    config.headers.Authorization = `Bearer ${UserService.authToken}`
  }

  return config
}

export function responseInterceptorSuccess (success) {
  return success
}

export function responseInterceptorError (error) {
  const { response } = error

  if (axios.isCancel(error)) return Promise.reject(new Error('CANCELED'))

  if (!response) return Promise.reject(new NetworkError(error.message))

  let customError

  switch (response.status) {
  case 401:
    localStorage.removeItem('@usr_t')
    return window.location.replace('/')
  case 404:
    customError = new NotFoundError({ message: 'Not found', status: response.status })
    break
  case 403:
    customError = new ForbiddenError({ message: 'Forbidden', status: response.status })
    break
  case 400:
  case 422:
    customError = response.data.errors
      ? new ServerValidationError({ errors: response.data.errors, status: response.status })
      : new ServerError(response)
    break
  case 500:
    Sentry.captureException(new InternalServerError(response), { tags: { statusCode: response.status, requestRoute: response.request.responseURL.split('?')[0] } })
    customError = new InternalServerError(response)
    break
  case 502:
  case 503:
    Sentry.captureException(new ServerUnavailable(), { tags: { statusCode: response.status, requestRoute: response.request.responseURL.split('?')[0] } })
    customError = new ServerUnavailable()
    break
  case 504:
    Sentry.captureException(new GatewayTimeout(), { tags: { statusCode: response.status, requestRoute: response.request.responseURL.split('?')[0] } })
    customError = new GatewayTimeout()
    break
  default:
    customError = new ServerError(response)
  }

  return Promise.reject(customError)
}
