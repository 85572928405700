import { formatISO } from 'date-fns'

import { PollQuestionTypes } from '@/constants/polls'

export const mapDtoToPoll = (dto) => ({
  id: dto.id,
  startedAt: dto.started_at && new Date(dto.started_at),
  endedAt: dto.ended_at && new Date(dto.ended_at),
  name: dto.name,
  state: dto.state,
  subscribersCount: dto.subscribers_count,
  subscribersInProcess: dto.subscribers_in_process,
  subscribersCompleted: dto.subscribers_completed,
  subscribersParticipant: dto.subscribers_participant
})

const mapDateToDto = (date) => date && formatISO(new Date(date))
export const mapFiltersDataToApiPayload = (filters) => {
  if (!filters) return undefined

  return {
    g: {
      0: {
        m: 'or',
        restaurants_in: filters.subsRestaurants
      },
      1: {
        m: 'or',
        cities_in: filters.subsCities
      }
    },
    genders_in: filters.subsGenders,
    categories_in: filters.subsCategories,
    subscribe_date_lteq: filters.subsEndedAt && mapDateToDto(filters.subsEndedAt),
    subscribe_date_gteq: filters.subsStartedAt && mapDateToDto(filters.subsStartedAt)
  }
}

export const mapPollDataToApiPayload = (data) => {
  const filters = {
    subsCategories: data.subsCategories,
    subsEndedAt: data.subsEndedAt,
    subsGenders: data.subsGenders,
    subsRestaurants: data.subsRestaurants,
    subsCities: data.subsCities,
    subsStartedAt: data.subsStartedAt
  }

  return {
    name: data.name,
    button_name: data.btnName,
    image: data.photo?.id,
    message: data.text,
    started_at: mapDateToDto(data.startDate),
    ended_at: mapDateToDto(data.endDate),
    filter: data.filterBySubscribers ? mapFiltersDataToApiPayload(filters) : undefined,
    notification_enable: data.notificationEnable,
    notification_message: data.notificationMessage,
    notification_date: mapDateToDto(data.notificationDate),
    final_message: data.completeMessage,
    poll_questions: data.questions.map((q) => ({
      serial_number: q.serialNumber,
      image: q.image?.id,
      text: q.text,
      kind: q.kind
    }))
  }
}

export const mapDtoToPollQuestion = (dto) => ({
  serialNumber: dto.serial_number,
  text: dto.text,
  kind: dto.kind,
  image: dto.image
    ? {
      id: dto.image.id,
      src: dto.image.url
    }
    : {
      id: null,
      src: null
    }
})

export const mapDtoToPollDetails = (dto) => ({
  id: dto.id,
  name: dto.name,
  btnName: dto.button_name,
  photo: dto.image
    ? {
      id: dto.image.id,
      src: dto.image.url
    }
    : {
      id: null,
      src: null
    },
  text: dto.message,
  startDate: dto.started_at && new Date(dto.started_at),
  endDate: dto.ended_at && new Date(dto.ended_at),
  filterBySubscribers: !!dto.filter,
  filters: {
    genders: dto.filter?.genders_in || [],
    restaurants: (dto.filter?.g && dto.filter.g['0']?.restaurants_in) || [],
    cities: (dto.filter?.g && dto.filter.g['1']?.cities_in) || [],
    categories: dto.filter?.categories_in || [],
    startedAt: dto.filter?.subscribe_date_gteq ? new Date(dto.filter.subscribe_date_gteq) : undefined,
    endedAt: dto.filter?.subscribe_date_lteq ? new Date(dto.filter.subscribe_date_lteq) : undefined
  },
  notificationEnable: dto.notification_enable,
  notificationMessage: dto.notification_message,
  notificationDate: dto.notification_date && new Date(dto.notification_date),
  completeMessage: dto.final_message,
  questions: dto.poll_questions.map(mapDtoToPollQuestion)
})

export const mapDtoToPollReportStatus = (dto) => ({
  id: dto.id,
  pollId: dto.data.poll_id,
  state: dto.state,
  currentStep: dto.data.current_step,
  totalSteps: dto.data.limit_step
})

export const mapDtoToPollReportData = (dto) => {
  const type = Object.values(PollQuestionTypes).find((qType) => qType.value === dto.kind)
  let answers = dto.answers

  if (Array.isArray(dto.answers)) {
    answers = dto.answers.map((a) => ({
      answer: a.answer != null ? a.answer : 0,
      count: a.count != null ? a.count : 0,
      percent: a.percent != null ? `${a.percent}` : 0
    }))
  }

  return {
    id: dto.id,
    type: dto.kind,
    typeText: type.text,
    image: dto.image_url,
    text: dto.text || '',
    missedCount: dto.count_answers_skipped || 0,
    answersCount: dto.count_answers || 0,
    answers,
    serialNumber: dto.serial_number
  }
}
