export default {
  inPlan: {
    name: 'В плане',
    value: 'init',
    colorName: 'blue'
  },
  sending: {
    name: 'Отправка',
    value: 'sending',
    colorName: 'yellow'
  },
  stopped: {
    name: 'Остановлена',
    value: 'stopped',
    colorName: 'red'
  },
  completed: {
    name: 'Завершено',
    value: 'completed',
    colorName: 'green'
  },
  pending: {
    name: 'Ожидание',
    value: 'pending',
    colorName: 'orange'
  },
  messaging: {
    name: 'Подготовка сообщений',
    value: 'messaging',
    colorName: 'orange'
  },
  prepared: {
    name: 'Ожидание отправки',
    value: 'prepared',
    colorName: 'orange'
  },
  pendingForClearing: {
    name: 'Очистка сообщений',
    value: 'pending_for_clearing',
    colorName: 'orange'
  },
  clearing: {
    name: 'Очистка сообщений',
    value: 'clearing',
    colorName: 'orange'
  },
  deleting: {
    name: 'Удаление',
    value: 'deleting',
    colorName: 'red'
  },
  archived: {
    name: 'Архивировано',
    value: 'archived',
    colorName: 'grey'
  }
}
