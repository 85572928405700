export default {
  read: {
    text: 'Прочитано',
    value: 'read',
    color: '#E5773B',
    colorName: 'orange'
  },
  notRead: {
    text: 'Не прочитано',
    value: 'notRead',
    color: '#BCC84C',
    colorName: 'olive'
  }
}
