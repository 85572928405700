import React, { ReactElement } from 'react'
import Dropzone, { DropEvent, FileRejection } from 'react-dropzone'
import cn from 'classnames'

import styles from './AppFileDropzone.module.scss'
import dropIcon from '@/images/dropIcon.svg'

type Props = {
  value: File | null;
  fileAccept: string;
  onFileAccept: (files: File[], event: DropEvent) => void;
  onFileReject: (fileRejections: FileRejection[], event: DropEvent) => void;
  renderFileValue(): ReactElement;
  placeholder?: string;
};

export default function AppFileDropzone (props: Props) {
  const {
    fileAccept,
    onFileAccept,
    onFileReject,
    renderFileValue,
    value,
    placeholder = 'Перетащите файл в это поле или нажмите для выбора'
  } = props

  return (
    <Dropzone accept={fileAccept} multiple={false} onDropAccepted={onFileAccept} onDropRejected={onFileReject}>
      {({ getRootProps, getInputProps, isDragAccept, isDragReject }) => (
        <div className={styles.host} {...getRootProps()}>
          <input {...getInputProps()} />
          <div
            className={cn(styles.dropZoneContent, 'dropzone-wrapper', {
              [styles.dragAccepted]: isDragAccept,
              [styles.dragRejected]: isDragReject
            })}
          >
            {value ? (
              renderFileValue()
            ) : (
              <>
                <img alt="Иконка дропзоны" src={dropIcon} />
                <span>{placeholder}</span>
              </>
            )}
          </div>
        </div>
      )}
    </Dropzone>
  )
}
