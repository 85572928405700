import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { User } from '@/models/entity'

type SliceState = {
  user: User | null;
};

const initialState: SliceState = {
  user: null
}

const profile = createSlice({
  name: 'profile',
  initialState,
  reducers: {
    setUser: (state, action: PayloadAction<User | null>) => {
      state.user = action.payload
    }
  }
})

export const { reducer: profileReducer } = profile
export const { setUser } = profile.actions
