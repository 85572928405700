import React from 'react'
import { Button, Icon } from 'semantic-ui-react'

import './SuccessPage.scss'

export default class SuccessPage extends React.Component {
  static defaultProps = {
    text: '',
    goBack: () => {}
  }

  render () {
    const { text, withGoBackButton } = this.props
    let renderedGoBackButton = null

    if (withGoBackButton) {
      renderedGoBackButton = (
        <div className='success-page__go-back-button-wrapper'>
          <Button
            onClick={() => this.props.goBack()}
            content='Вернуться назад'
            color='yellow'
          />
        </div>
      )
    }

    return (
      <div className='success-page'>
        <Icon className='success-page__icon' name='check circle outline' color='green' />

        <div className='success-page__text'>
          {text}
        </div>

        {renderedGoBackButton}
      </div>
    )
  }
}
