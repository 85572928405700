import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { Dialog } from '@/models/entity/dialog/Dialog'

type SliceState = {
  currDialog: Dialog | undefined
  getCurrentDialog: ((dialogId: string) => void) | undefined
  readMessages: ((dialogId: string, ids: string[]) => Promise<null | undefined>) | undefined
}

const initialState: SliceState = {
  currDialog: undefined,
  getCurrentDialog: undefined,
  readMessages: undefined
}

const chat = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setCurrDialog: (state, action: PayloadAction<Dialog>) => {
      state.currDialog = action.payload
    },
    setGetCurrentDialog: (state, action: PayloadAction<(dialogId: string) => void>) => {
      state.getCurrentDialog = action.payload
    },
    setReadMessages: (state, action: PayloadAction<(dialogId: string, ids: string[]) => Promise<null | undefined>>) => {
      state.readMessages = action.payload
    }
  }
})

export const { reducer: chatReducer } = chat

export const { setCurrDialog, setGetCurrentDialog, setReadMessages } = chat.actions
