import { API_URL } from '@/api/httpClient'
import BaseService from './BaseService'

export default class AnalyticsService extends BaseService {
  static async sendPageOpenEvent (registration_token) {
    const payload = new FormData()
    payload.append('kind', 'registration_page_opened')

    navigator.sendBeacon(`${API_URL}/public/events/registration/${registration_token}`, payload)
  }

  static async sendPageClosedEvent (registration_token) {
    const payload = new FormData()
    payload.append('kind', 'registration_page_closed')

    navigator.sendBeacon(`${API_URL}/public/events/registration/${registration_token}`, payload)
  }
}
