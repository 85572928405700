type Constant = {
  bonus: {
    value: 'bonus',
    name: 'Бонусный'
  },
  deposit: {
    value: 'deposit',
    name: 'Депозитный'
  }
}

const balanceTypes: Constant = {
  bonus: {
    value: 'bonus',
    name: 'Бонусный'
  },
  deposit: {
    value: 'deposit',
    name: 'Депозитный'
  }
}

export default balanceTypes
