import saveAs from 'file-saver'
import { AxiosResponse } from 'axios'

import BaseService from '../BaseService'
import { mapDtoToPromotion, mapDtoToTriggerCounters } from '@/utils/mappers/promotion-mappers'
import { API_URL, httpClient } from '@/api/httpClient'
import { UrlService } from '..'
import { TriggerCreatePayload } from '.'
import { TriggerShortLink, TriggerSubscriberCategory, TriggerPromotion } from '@/models/entity/promotions/trigger'
import { PromotionEventTypes } from '@/constants'
import { ValueTypes as TriggerEventType } from '@/constants/promotion/promotion-event-types'

export default class PromotionsTriggerService extends BaseService {
  static allCategoriesEndpoint = `${API_URL}/protected/subscriber_categories`;

  static getTriggerMessagesEndpoint (triggerId: string) {
    return `${API_URL}/protected/promotions/trigger/${triggerId}/report`
  }

  static async create (data: TriggerCreatePayload) {
    return httpClient.post('/protected/promotions/trigger', data)
  }

  static async check (formData: TriggerCreatePayload) {
    type Response = {
      status: string;
    };
    const { data } = await httpClient.post<TriggerCreatePayload, AxiosResponse<Response>>(
      '/protected/promotions/trigger/check',
      formData
    )

    return data
  }

  static async save (id: string, data: TriggerCreatePayload) {
    return httpClient.post(`/protected/promotions/trigger/${id}`, data)
  }

  static async delete (id: string) {
    return httpClient.delete(`/protected/promotions/trigger/${id}`)
  }

  static async stop (id: string) {
    return httpClient.get(`/protected/promotions/trigger/${id}/stop`)
  }

  static async finish (id: string) {
    return httpClient.get(`/protected/promotions/trigger/${id}/finish`)
  }

  static async start (id: string) {
    return httpClient.get(`/protected/promotions/trigger/${id}/start`)
  }

  static async getDetails (id: string) {
    const query = UrlService.getFiltersQuery({ id_cont: id })
    const { data } = await httpClient.get<never, AxiosResponse<TriggerPromotion[]>>(`/protected/promotions/trigger?${query}`)
    const dto = data[0]
    const trigger = mapDtoToPromotion(dto)

    return trigger
  }

  static async getTriggerCounters (id: string, query: any) {
    const q = UrlService.getFiltersQuery(query)
    const { data } = await httpClient.get(`/protected/promotions/trigger/${id}/summary?${q}`)

    return mapDtoToTriggerCounters(data)
  }

  static async getSubscriberCategories () {
    const { data } = await httpClient.get<never, AxiosResponse<TriggerSubscriberCategory[]>>(
      '/protected/subscriber_categories'
    )

    return data
  }

  static async getJoinUsLinks () {
    const { data } = await httpClient.get<never, AxiosResponse<TriggerShortLink[]>>(
      '/protected/promotions/trigger/short_links_list'
    )

    return data
  }

  static async getTriggerReport (id: string, query: any) {
    const q = UrlService.getFiltersQuery(query)
    const response = await httpClient.get(`/protected/promotions/trigger/${id}/report/download?${q}`, {
      responseType: 'blob'
    })
    const fileName = this.getFileNameByContentDisposition(response.headers['content-disposition'])

    saveAs(response.data, fileName)
  }

  static mapTriggerEventToDescription = (triggerEvent: TriggerEventType, restaurantName = '') => {
    switch (triggerEvent) {
    case PromotionEventTypes.subscribe.value:
      return [ restaurantName, `Триггер: “${PromotionEventTypes.subscribe.text}”` ].join(' ')

    case PromotionEventTypes.daysFromLastVisit.value:
      return [ restaurantName, `Триггер: “${PromotionEventTypes.daysFromLastVisit.text}”` ].join(' ')

    case PromotionEventTypes.sum.value:
      return [ restaurantName, `Триггер: “${PromotionEventTypes.sum.text}”` ].join(' ')

    case PromotionEventTypes.birthday.value:
      return [ restaurantName, `Триггер: “${PromotionEventTypes.birthday.text}”` ].join(' ')

    case PromotionEventTypes.childBirthday.value:
      return [ restaurantName, `Триггер: “${PromotionEventTypes.childBirthday.text}”` ].join(' ')

    case PromotionEventTypes.addGuestToIikoCards.value:
      return `Триггер: “${PromotionEventTypes.addGuestToIikoCards.text}”`

    default:
      return ''
    }
  }

  static async getTriggerPromotions (payload: { page: string, per_page: string }, payloadQ: any) {
    const filters = new URLSearchParams()
    filters.set('page', payload.page)
    filters.set('per_page', payload.per_page)

    const q = payloadQ === null ? payloadQ : UrlService.getFiltersQuery(payloadQ)

    const data = await httpClient.get(`/protected/promotions/trigger?${filters}&${q || ''}`)

    return data
  }

  static getTriggerPromotionsReportEndpoint (triggerId: string, payload: any, payloadQ: any) {
    const filters = new URLSearchParams()
    filters.set('page', payload.page)
    filters.set('per_page', payload.per_page)

    payload.message_status && filters.set('message_status', payload.message_status)
    payload.message_status && filters.set('message_status', payload.message_status)

    payload.has_kinds && payload.has_kinds.forEach((id: any) => {
      filters.append('has_kinds[]', id)
    })
    payload.has_no_kinds && payload.has_no_kinds.forEach((id: any) => {
      filters.append('has_no_kinds[]', id)
    })

    const q = UrlService.getFiltersQuery(payloadQ)

    return `/protected/promotions/trigger/${triggerId}/report?${filters}&${q}`
  }

  static async getTriggerPromotionsReport (endpoint: string) {
    const data = await httpClient.get(endpoint)

    return data
  }
}
