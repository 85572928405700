import { AxiosResponse } from 'axios'

import BaseService from './BaseService'
import { mapDtoToTemplateMessage } from '../utils/mappers/settings-mappers'
import { httpClient } from '@/api/httpClient'
import { Settings } from '@/models/entity/settings/Settings'

export default class SettingsService extends BaseService {
  static async saveSettings (data: any) {
    return httpClient.post('/protected/settings', data)
  }

  static async saveProfile (data: any) {
    return httpClient.post('/protected/profile', data)
  }

  static async connectIiko (data: any) {
    return httpClient.put('/protected/settings/iiko/connect', data)
  }

  static async syncIiko () {
    return httpClient.get('/protected/settings/iiko/sync')
  }

  static async getSettings () {
    const { data } = await httpClient.get<null, AxiosResponse<Settings>>('/protected/settings')

    return data
  }

  static async getBulkMessage () {
    const { data } = await httpClient.get('/protected/settings/bulk_message')

    return data
  }

  static async setBulkMessage (data: any) {
    return httpClient.post('/protected/settings/bulk_message', data)
  }

  static async sendSms (phone: any) {
    return httpClient.post('/protected/settings/send_message', { phone })
  }

  static async resetBulksCounters () {
    return httpClient.delete('/protected/settings/bulks/counters')
  }

  static async getMessagesTemplates () {
    type Response = {
      created_at: string;
      id: string;
      text: string;
      title: string;
      updated_at: string;
    };
    const { data } = await httpClient.get<null, AxiosResponse<Response[]>>('/protected/settings/templates')
    const templates = data.map(mapDtoToTemplateMessage)

    return templates
  }

  static async setActiveChatbotGroup (id: any) {
    return httpClient.post('/protected/settings/active_chatbot_group', { chatbot_group_id: id })
  }

  static async createMenu (payload: { name: string; link: string | undefined; file: File | undefined }) {
    const formData = new FormData()
    formData.append('name', payload.name)
    if (payload.link) formData.append('link', payload.link)
    if (payload.file) formData.append('file', payload.file)

    return httpClient.post('/protected/menus', formData)
  }

  static async deleteMenu (linkId: string) {
    return httpClient.delete(`/protected/menus/${linkId}`)
  }
}
