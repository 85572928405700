import { httpClient } from '@/api/httpClient'
import { UrlService } from './'
import BaseService from './BaseService'

export default class AppSelectService extends BaseService {
  /**
   * Loading options from API.
   * @param {string} endpoint - Endpoint URL to load options.
   * @param {string} valueKey - The key of item has to be a value of the option.
   * @param {Function} labelBuilder - Builder function of option's label.
   * @param {Object} queryParams - Query params.
   * @param {number} page - Page number.
   * @returns {Object[]} Options array.
   */
  static async getOptions (
    endpoint,
    valueKey,
    labelBuilder = () => {},
    queryParams = {},
    page = 1
  ) {
    const queryString = UrlService.getFiltersQuery(queryParams)
    const { data } = await httpClient.get(`${endpoint}?page=${page}&${queryString}`)
    const options = data.map(item => ({
      value: item[valueKey],
      label: labelBuilder(item),
      key: item[valueKey]
    }))

    return options
  }
}
