import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type SliceState = {
  childrenEnabled: boolean
  chatbotGroupId: string
  routingKind: string
  disableDeposit: boolean
  deliveryEnabled: boolean
};

const initialState: SliceState = {
  childrenEnabled: true,
  chatbotGroupId: '',
  routingKind: '',
  disableDeposit: false,
  deliveryEnabled: false
}

const settings = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setChildrenEnabled: (state, action: PayloadAction<boolean>) => {
      state.childrenEnabled = action.payload
    },
    setChatbotGroupId: (state, action: PayloadAction<string>) => {
      state.chatbotGroupId = action.payload
    },
    setRoutingKind: (state, action: PayloadAction<string>) => {
      state.routingKind = action.payload
    },
    setDepositDisabled: (state, action: PayloadAction<boolean>) => {
      state.disableDeposit = action.payload
    },
    setDeliveryEnabled: (state, action: PayloadAction<boolean>) => {
      state.deliveryEnabled = action.payload
    }
  }
})

export const { reducer: settingsReducer } = settings

export const { setChildrenEnabled, setChatbotGroupId, setRoutingKind, setDepositDisabled, setDeliveryEnabled } = settings.actions
