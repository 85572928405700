import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Doughnut } from 'react-chartjs-2'

import './AppInfographicsCircle.scss'

import { CommonService } from '@/services'

function AppInfographicsCircle (props) {
  const { config } = props

  // eslint-disable-next-line no-return-assign
  const isEmptyState = Object.values(config).reduce((total, current) => (total += current.amount), 0) === 0
  const colors = Object.values(config).map((c) => c.color)
  const chartData = {
    labels: Object.values(config).map((c) => c.title),
    datasets: [
      {
        data: Object.values(config).map((c) => c.amount),
        backgroundColor: colors,
        borderColor: colors,
        borderWidth: 0
      }
    ]
  }

  //
  // Render
  //

  const renderCenter = () => {
    const breaks = Object.values(config)

    return (
      <section className="app-infographics-circle__center">
        {breaks.map((c, index) => {
          const isLast = index === breaks.length - 1

          if (isLast) {
            return (
              <span key={index} style={{ color: c.color }}>
                {c.amount}
              </span>
            )
          }

          return (
            <Fragment key={index}>
              <span style={{ color: c.color }}>{c.amount}</span>
              <span>/</span>
            </Fragment>
          )
        })}
      </section>
    )
  }

  const renderLegend = (
    <section className="app-infographics-circle__legend">
      {Object.values(config).map((c, index) => (
        <div key={index} className="app-infographics-circle__legend-item">
          <div className="app-infographics-circle__legend-item-label" style={{ background: c.color }}>
            {c.percent}%
          </div>
          <p>{c.title}</p>
        </div>
      ))}
    </section>
  )

  const renderEmptyChart = isEmptyState && <div className="app-infographics-circle__empty-chart"></div>

  return (
    <div className="app-infographics-circle">
      <div className="app-infographics-circle__inner">
        {renderCenter()}
        {renderEmptyChart}

        <Doughnut
          options={{
            cutout: 90,
            maintainAspectRatio: false,
            responsive: false,
            plugins: {
              legend: {
                display: false
              },
              tooltip: {
                callbacks: {
                  label: function (context) {
                    let sum = 0
                    const dataArr = context.dataset.data
                    dataArr.forEach((data) => {
                      sum += Number(data)
                    })
                    const percentage = +parseFloat((100 * context.parsed) / (sum || 1)).toFixed(2) + '%'

                    return `${context.label}: ${CommonService.formatNumber(context.parsed)} (${percentage})`
                  }
                }
              }
            }
          }}
          width={260}
          height={260}
          className="app-infographics-circle__circle"
          data={chartData}
        />
      </div>
      {renderLegend}
    </div>
  )
}

AppInfographicsCircle.propTypes = {
  config: PropTypes.array.isRequired
}

export default AppInfographicsCircle
