import React, { FC } from 'react'
import Select from 'react-select'

type Props = {
  options: {
    value: string;
    label: string;
    key: string;
  }[] | any
  value: any
  onChange: (value: any) => void
  placeholder?: string
  className?: string
}

const selectAllOption = {
  value: 'selectAll',
  key: 'selectAll',
  label: 'Все'
}

const MultiSelect: FC<Props> = ({ options, value, onChange, placeholder, className }) => {
  return (
    <Select
      options={(value[0] && value[0].value === 'selectAll') ? [ selectAllOption ] : [ selectAllOption, ...options ]}
      value={value}
      onChange={(newValue) => {
        if (newValue.find(item => item.value === 'selectAll')) onChange([ selectAllOption ])
        else onChange(newValue)
      }}
      placeholder={placeholder}
      className={className}
      isMulti
      isSearchable
      isClearable={false}
      noOptionsMessage={() => 'Ничего не найдено'}
      components={{
        IndicatorSeparator: () => null
      }}
      styles={{
        valueContainer: provided => ({
          ...provided,
          cursor: 'text'
        }),
        control: (provided, { menuIsOpen }) => {
          return {
            ...provided,
            borderColor: menuIsOpen ? 'rgb(150, 200, 218) !important' : 'rgba(34, 36, 38, 0.15)',
            boxShadow: 'none',
            '&:hover': {
              borderColor: 'rgba(34, 36, 38, 0.35)'
            },
            '& > div:first-of-type': {
              maxHeight: '97px',
              overflowY: 'auto'
            }
          }
        },
        placeholder: provided => ({
          ...provided,
          color: 'rgba(191, 191, 191, 0.87)'
        }),
        menu: (provided) => {
          const resState = {
            ...provided,
            zIndex: 10000
          }

          return resState
        },
        option: (provided, { data }) => {
          const resState = {
            ...provided,
            cursor: 'pointer',
            zIndex: 10000,
            color: 'rgba(0, 0, 0, 0.95)',
            background: 'none',
            '&:hover,&:active': {
              background: 'rgba(0, 0, 0, 0.05)'
            }
          }

          if (data.value === 'selectAll') {
            resState.fontWeight = 'bold'
          }

          return resState
        },
        multiValue: provided => ({
          ...provided,
          border: '1px solid #e0e0e0'
        }),
        multiValueRemove: provided => ({
          ...provided,
          cursor: 'pointer'
        }),
        dropdownIndicator: provided => ({
          ...provided,
          cursor: 'pointer'
        })
      }}
    />
  )
}

export default MultiSelect
