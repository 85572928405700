import { uniqueId } from 'lodash-es'

import { mapDtoToRestaurant } from './restaurant-mappers'
import { TriggerDeliverStatuses } from '@/constants'
import { getPercentage } from '@/utils/mathUtils'
import { TriggerMessage, TriggerPromotion } from '@/models/entity/promotions/trigger'
import { TriggerPromotionMapped } from '@/models/entity/promotions/trigger/TriggerPromotionMapped'
import { TriggerCounters } from '@/models/entity/promotions/trigger/TriggerCounters'
import { TriggerCountersMapped } from '@/models/entity/promotions/trigger/TriggerCountersMapped'
import { TriggerSubscriber } from '@/models/entity/promotions/trigger/TriggerSubscriber'
import DeliveryPromotion, { DeliveryPromotionDto } from '@/models/entity/promotions/delivery/DeliveryPromotion'

export const mapDtoToPromotion = (dto: TriggerPromotion): TriggerPromotionMapped => ({
  id: dto.id,
  bonus: dto.bonus,
  bonusDays: dto.bonus_days,
  checkAmount: dto.check_amount,
  daysLastVisit: dto.days_last_visit,
  disabled: dto.disabled,
  giftType: dto.gift_type,
  indefinite: dto.indefinite,
  message: dto.message && mapDtoToPromotionMessage(dto.message),
  name: dto.name,
  notificationDays: dto.notification_days,
  notificationEnable: dto.notification_enable,
  notificationMessage: dto.notification_message,
  restaurants: dto.restaurants && Array.isArray(dto.restaurants) && dto.restaurants.map(mapDtoToRestaurant),
  status: dto.status,
  subscriberCategory: dto.subscriber_category,
  triggerCount: dto.trigger_count,
  triggerEvent: dto.trigger_event,
  createdAt: dto.created_at && new Date(dto.created_at),
  endedAt: dto.ended_at && new Date(dto.ended_at),
  startedAt: dto.started_at && new Date(dto.started_at),
  updatedAt: dto.updated_at && new Date(dto.updated_at)
})

const mapDtoToPromotionMessage = (dto: TriggerMessage) => ({
  imageId: dto.image,
  image: dto.imagePreview,
  text: dto.text
})

export const mapDtoToTriggerSubscriber = (dto: TriggerSubscriber) => {
  let status = null

  if (dto.message_pending) status = TriggerDeliverStatuses.inQueue.value
  if (dto.message_sent) status = TriggerDeliverStatuses.sent.value
  if (dto.message_undelivered) status = TriggerDeliverStatuses.undelivered.value

  return {
    id: uniqueId(),
    date: dto.created_at,
    avatar: dto.subscriber && dto.subscriber.avatar,
    fullName: dto.subscriber && dto.subscriber.full_name,
    phone: dto.subscriber && dto.subscriber.phone,
    status,
    read: dto.read,
    used: dto.responded,
    order: {
      date: dto.iiko_created_at,
      number: dto.iiko_order_number,
      sum: dto.amount
    }
  }
}

export const mapDtoToTriggerCounters = (dto: TriggerCounters): TriggerCountersMapped => {
  const count = dto.message_total || 0
  const sent = dto.message_sent || 0
  const inQueue = dto.message_pending || 0
  const notDelivered = dto.message_undelivered || 0
  const read = dto.read || 0
  const notRead = count - read || 0
  const used = dto.responded || 0
  const notUsed = count - used || 0

  return {
    sent,
    inQueue,
    notDelivered,
    read,
    notRead: notRead < 0 ? 0 : notRead,
    used,
    notUsed: notUsed < 0 ? 0 : notUsed,
    sentPercent: getPercentage(sent, count),
    inQueuePercent: getPercentage(inQueue, count),
    notDeliveredPercent: getPercentage(notDelivered, count),
    readPercent: getPercentage(read, count),
    notReadPercent: getPercentage(notRead, count),
    usedPercent: getPercentage(used, count),
    notUsedPercent: getPercentage(notUsed, count),
    totalSum: parseFloat(dto.amount_sum),
    avgSum: parseFloat(dto.amount_avg)
  }
}

export const mapDtoToDeliveryPromotion = (dto: DeliveryPromotionDto): DeliveryPromotion => {
  return new DeliveryPromotion({
    id: dto.id!,
    name: dto.name ?? '',
    createdAt: dto.created_at ?? '',
    description: dto.description ?? '',
    menuIikoCode: dto.menu_iiko_code ?? '',
    position: dto.position ?? null,
    endedAt: dto.ended_at ?? '',
    indefinite: dto.indefinite ?? false,
    deliveryStores: dto.delivery_stores ?? [],
    backgroundImage: dto.background_image ?? null,
    backgroundImageApp: dto.background_image_app ?? null,
    backgroundImageBanner: dto.background_image_banner ?? null,
    url: dto.url ?? '',
    startedAt: dto.started_at ?? '',
    status: dto.status ?? null,
    updatedAt: dto.updated_at ?? ''
  })
}
