import { createSlice, PayloadAction } from '@reduxjs/toolkit'

type SliceState = {
  sidebarVisible: boolean;
};

const initialState: SliceState = {
  sidebarVisible: false
}

const layout = createSlice({
  name: 'layout',
  initialState,
  reducers: {
    setSidebarVisible: (state, action: PayloadAction<boolean>) => {
      state.sidebarVisible = action.payload
    }
  }
})

export const { reducer: layoutReducer } = layout

export const { setSidebarVisible } = layout.actions
