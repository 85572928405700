export enum ValueTypes {
  inform = 'info',
  bonuses = 'bonus',
  gift = 'gift',
}

const config = {
  inform: {
    name: 'Информационное',
    value: ValueTypes.inform
  },
  bonuses: {
    name: 'Бонусы',
    value: ValueTypes.bonuses
  },
  gift: {
    name: 'Подарок',
    value: ValueTypes.gift
  }
}

export default config
