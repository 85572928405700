export function getRandomNumber (min, max) {
  const rand = min + Math.random() * (max + 1 - min)

  return Math.floor(rand)
}

export function getPercentage (partialValue, totalValue) {
  if (totalValue === 0) return 0

  return +parseFloat((100 * partialValue) / totalValue).toFixed(2)
}
