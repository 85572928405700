import React, { ReactNode } from 'react'
import { Button, Dropdown, Menu, Table } from 'semantic-ui-react'
import cx from 'classnames'

import './AppTable.scss'

type Props = {
  content: { data: any, count: number}
  columns: {
    header?: string | ReactNode,
    cell: (item: any, index: number | string, state?: any) => any,
    cellProps?: any,
    headerCellProps?: any
    withNestedTable?: boolean
    sortField?: string
  }[]
  requestError: boolean
  unstackable?: boolean
  onChange: (page: number, itemsPerPage: number, sorting?: { field: string | null, direction: 'descending' | 'ascending' | undefined }) => void
  updateData: () => void
  page: number
  itemsPerPage: number
  totalPages: number
  sorting?: { field: string | null, direction: 'descending' | 'ascending' | undefined }
  className?: string
}

const ITEMS_PER_PAGE = [ { key: 50, value: 50, text: 50 }, { key: 100, value: 100, text: 100 }, { key: 200, value: 200, text: 200 } ]

const AppTable: React.FC<Props> = ({ content, columns, requestError, unstackable, onChange, updateData, page, itemsPerPage, totalPages, sorting, className }) => {
  const createCells = () => {
    const rows = []

    for (const i in content.data) {
      const item: any = content.data[i]

      const newCells = []

      for (const j in columns) {
        const column = columns[j]
        const cellProps = column.cellProps
        let cellClasses = ''

        if (column.withNestedTable) cellClasses += 'appTable__cell-with-nested-table '

        newCells.push(
          <Table.Cell
            key={`${item.id}-${j}`}
            {...cellProps}
            className={cx(cellClasses, (cellProps && cellProps.className) || '')}
          >
            <div className="appTable__mobile-cell-header">{column.header}</div>
            {column.cell(item, i)}
          </Table.Cell>
        )
      }

      rows.push(
        <Table.Row key={`${item.id}-${i}`}>
          {newCells}
        </Table.Row>
      )
    }

    return rows
  }

  const handleChangeSorting = (newSortField: string) => {
    if (sorting) {
      if (newSortField === sorting.field) {
        if (sorting.direction === 'descending') {
          onChange(page, itemsPerPage, { field: sorting.field, direction: 'ascending' })
        }
        if (sorting.direction === 'ascending') {
          onChange(page, itemsPerPage, { field: sorting.field, direction: 'descending' })
        }
      } else {
        onChange(page, itemsPerPage, { field: newSortField, direction: 'descending' })
      }
    }
  }

  const handleChangePage = (newPage: number) => {
    if (newPage < 1) return
    if (newPage > totalPages) return

    onChange(newPage, itemsPerPage, sorting)
  }

  return (
    <div className={cx('appTable-wrapper dimmable', className || '')}>
      <div className={`appTable-outer ${unstackable ? 'appTable-outer_scrolled' : ''}`}>
        <Table className={'appTable' } celled sortable>
          <Table.Header>
            <Table.Row>
              {columns.map(column =>
                <Table.HeaderCell
                  disabled={typeof column.sortField !== 'string'}
                  sorted={sorting && (column.sortField === sorting.field ? sorting.direction : undefined)}
                  onClick={() => column.sortField && handleChangeSorting(column.sortField)}
                >
                  {column.header}
                </Table.HeaderCell>)}
            </Table.Row>
          </Table.Header>

          <Table.Body>
            {requestError && <Table.Row>
              <Table.Cell textAlign="center" colSpan={columns.length}>
                <h4>Не удалось получить данные с сервера</h4>
                <Button color="blue" onClick={() => updateData()}>
                  Попробовать еще раз
                </Button>
              </Table.Cell>
            </Table.Row>}

            {!content.count && !requestError && <Table.Row>
              <Table.Cell textAlign="center" colSpan={columns.length}>
                <h4>Пока нет ни одной записи</h4>
              </Table.Cell>
            </Table.Row>}

            {createCells()}
          </Table.Body>

          <Table.Footer>
            <Table.Row>
              <Table.HeaderCell colSpan={columns.length}>
                <span className="item-per-page-caption">
                Показывать
                  <Dropdown
                    inline
                    floating
                    defaultValue={ITEMS_PER_PAGE[0].value}
                    value={itemsPerPage}
                    options={ITEMS_PER_PAGE}
                    onChange={(_, { value }) => {
                      typeof value === 'number' && onChange(page, value, sorting)
                    }}
                    className="item-per-page-dropdown"
                    upward={true}
                    icon="triangle up"
                  />
                записей на странице
                </span>

                <Menu floated="right" pagination>
                  {page > 2 &&
                    <Menu.Item as="a" onClick={() => handleChangePage(1)}>
                      1
                    </Menu.Item>
                  }

                  {<Menu.Item
                    disabled={page <= 1}
                    as="a"
                    onClick={() => handleChangePage(page - 1)}
                    icon="left chevron"
                  />}

                  {page > 1 &&
                    <Menu.Item as="a" onClick={() => handleChangePage(page - 1)}>
                      {page - 1}
                    </Menu.Item>
                  }

                  {<Menu.Item as="a" active>
                    {page}
                  </Menu.Item>}

                  {page < totalPages &&
                    <Menu.Item as="a" onClick={() => handleChangePage(page + 1)}>
                      {page + 1}
                    </Menu.Item>
                  }

                  {<Menu.Item
                    as="a"
                    disabled={page >= totalPages}
                    onClick={() => handleChangePage(page + 1)}
                    icon="right chevron"
                  />}

                  {page < totalPages - 1 &&
                    <Menu.Item as="a" onClick={() => handleChangePage(totalPages)}>
                      {totalPages}
                    </Menu.Item>
                  }
                </Menu>

                <span className="appTable__pages-counter">
                  Показано {itemsPerPage * (page - 1) + 1} - {Math.min(page * itemsPerPage, content.count)} из {content.count} записей
                </span>
              </Table.HeaderCell>
            </Table.Row>
          </Table.Footer>
        </Table>
      </div>
    </div>
  )
}

export default AppTable
