import React from 'react'
import { Picker } from 'emoji-mart'
import { Icon } from 'semantic-ui-react'

import 'emoji-mart/css/emoji-mart.css'
import './EmojiPicker.css'
import { Emoji } from '@/models/entity/emoji/Emoji'

type Props = {
  handleAddEmoji: (emoji: Emoji) => void
}

type State = {
  displayEmojiPicker: boolean
}

class EmojiPicker extends React.Component<Props, State> {
  state = {
    displayEmojiPicker: false
  }

  handleClick () {
    this.setState({ displayEmojiPicker: !this.state.displayEmojiPicker })
  }

  handleClose () {
    this.setState({ displayEmojiPicker: false })
  }

  handleAddEmoji (emoji: Emoji) {
    if (this.props.handleAddEmoji) {
      this.props.handleAddEmoji(emoji)
      this.handleClick()
    }
  }

  render () {
    let emojiPicker = null

    if (this.state.displayEmojiPicker) {
      emojiPicker = (
        <div className='emoji-picker__popover'>
          <div className='emoji-picker__cover' onClick={this.handleClose.bind(this)}/>
          <Picker showPreview={false} native emojiSize={16} onClick={this.handleAddEmoji.bind(this)} />
        </div>
      )
    }
    const openClass = this.state.displayEmojiPicker ? 'open' : ''

    return (
      <div className={`emoji-picker ${openClass}`}>
        <Icon name='smile' size='large' onClick={this.handleClick.bind(this)} />

        {emojiPicker}
      </div>
    )
  }
}

export default EmojiPicker
