import { configureStore } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import { profileReducer } from './profileSlice'
import { layoutReducer } from './layoutSlice'
import { settingsReducer } from './settingsSlice'
import { chatReducer } from './chatSlice'

export const store = configureStore({
  reducer: {
    profile: profileReducer,
    layout: layoutReducer,
    settings: settingsReducer,
    chat: chatReducer
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false })
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch<AppDispatch>()
