import saveAs from 'file-saver'
import { AxiosResponse } from 'axios'

import BaseService from './BaseService'
import { API_URL, httpClient } from '@/api/httpClient'
import { mapDtoToBulkSummary } from '@/utils/mappers/bulk-mappers'
import UrlService from './UrlService'

export default class BulkService extends BaseService {
  static getBulkMessagesEndpoint (bulkId: any) {
    return `${API_URL}/protected/bulks/${bulkId}/report`
  }

  static async getSubscribersCount (send_type: string, filters = {}) {
    const { data } = await httpClient.post<{ send_type: string, filters: any }, AxiosResponse<{ count: number }>>('/protected/bulks/subscribers', { send_type, filters })

    return data
  }

  static async getCategories () {
    const { data } = await httpClient.get('/protected/bulks/categories')

    return data
  }

  static async getRestaurants () {
    const { data } = await httpClient.get('/protected/bulks/restaurants')

    return data
  }

  static async createBulk (data: any) {
    return httpClient.post('/protected/bulks', data)
  }

  static async deleteBulk (bulkId: any) {
    return httpClient.delete(`/protected/bulks/${bulkId}`)
  }

  static async stopBulk (bulkId: any) {
    return httpClient.put(`/protected/bulks/${bulkId}/stop`)
  }

  static async saveBulk (id: any, data: any) {
    return httpClient.put(`/protected/bulks/${id}`, data)
  }

  static async getSummary (id: any) {
    const { data } = await httpClient.get(`/protected/bulks/${id}/report/summary`)
    const bulkSummary = mapDtoToBulkSummary(data)

    return bulkSummary
  }

  static async downloadBulkReport (linkId: any, querySnapshot: any) {
    const response = await httpClient.get(`/protected/bulks/${linkId}/report/download?${querySnapshot}`, { responseType: 'blob', timeout: 300 * 1000 })
    const fileName = this.getFileNameByContentDisposition(response.headers['content-disposition'])

    saveAs(response.data, fileName)
  }

  static async getBulkReport (bulkId: string, payload: any, payloadQ: any) {
    const filters = new URLSearchParams()
    filters.set('page', payload.page)
    filters.set('per_page', payload.per_page)
    payload.message_status && filters.set('message_status', payload.message_status)
    payload.used && filters.set('used', payload.used)
    payload.read && filters.set('read', payload.read)

    let q = {}
    if (payloadQ.filter_search) {
      q = UrlService.getFiltersQuery({ sorts: payloadQ.sorts, subscriber_phone_or_subscriber_first_name_or_subscriber_middle_name_or_subscriber_last_name_cont: payloadQ.filter_search })
    } else {
      q = UrlService.getFiltersQuery(payloadQ)
    }

    const data = await httpClient.get(`/protected/bulks/${bulkId}/report?${filters}&${q}`)

    return data
  }

  static async getBulks (payload: { page: number, per_page: number }, payloadQ: any) {
    const filters = new URLSearchParams()
    filters.set('page', payload.page.toString())
    filters.set('per_page', payload.per_page.toString())

    const q = UrlService.getFiltersQuery(payloadQ)

    const data = await httpClient.get(`protected/bulks?${filters}&${q}`)

    return data
  }
}
