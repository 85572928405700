import React, { Component } from 'react'
import { Button } from 'semantic-ui-react'

import './ButtonsCheckbox.scss'

export default class ButtonsCheckbox extends Component {
  static defaultProps = {
    icon: null,
    text: null,
    checked: false,
    activeColor: 'green',
    onChange: () => {},
    fluid: false,
    disabled: false
  }

  render () {
    const { icon, text, checked, onChange, fluid, disabled, activeColor } = this.props
    const color = checked ? activeColor : null

    return (
      <Button
        className='buttons-checkbox'
        type='button'
        active={checked}
        icon={icon}
        color={color}
        content={text}
        onClick={onChange.bind(this, !checked)}
        fluid={fluid}
        disabled={disabled}
      />
    )
  }
}
