import { httpClient } from '@/api/httpClient'
import BaseService from './BaseService'

export default class UberTableService extends BaseService {
  static async getData (url, mapper) {
    const response = await httpClient.get(url)
    const count = response.headers['x-total']
    let { data } = response

    if (mapper && data.length > 0) {
      data = data.map(mapper)
    }

    return { items: data, count }
  }
}
