import { AxiosResponse } from 'axios'

import BaseService from './BaseService'
import { ActionCableCommands } from '@/constants/action-cable'
import { httpClient } from '@/api/httpClient'
import { Dialog } from '@/models/entity/dialog/Dialog'
import { ActiveOrders } from '@/models/entity/orders/ActiveOrders'
import { Message } from '@/models/entity/message/Message'

export default class ChatsService extends BaseService {
  static async getDialogs (page: number, q: string | null = null) {
    const response = await httpClient.get<null, AxiosResponse<Dialog[]>>(`/protected/chats/dialogs?page=${page}&${q}`)
    const count = response.headers['x-total']
    const { data } = response

    return { items: data, count }
  }

  static async getDialogMessages (dialogId: string, page: number) {
    const response = await httpClient.get<null, AxiosResponse<Message[]>>(`/protected/chats/${dialogId}/messages?page=${page}`)
    const count = response.headers['x-total']
    const { data } = response

    return { items: data, count }
  }

  static async readMessages (dialogId: string, ids: string[]) {
    return httpClient.post(`/protected/chats/${dialogId}/read`, { ids })
  }

  static async getDialogBySubscriber (dialogId: string) {
    const { data } = await httpClient.get<Dialog>(`/protected/chats/${dialogId}`)

    return data
  }

  static async sendMessage (dialogId: string, message: string, image?: number | null) {
    return httpClient.post(`/protected/chats/${dialogId}/messages`, { message: message, image })
  }

  // Ниже все для оператора

  static async getActiveOrders (dialogId: string) {
    const { data } = await httpClient.get<null, AxiosResponse<ActiveOrders[]>>(`/protected/chats/${dialogId}/active_orders`)

    return data
  }

  static requestOperatorWritingInfo (subscription: any, operatorId: string) {
    if (!subscription) return

    subscription.send({
      action: ActionCableCommands.GET_OPERATOR_WRITING_INFO,
      recipient_id: operatorId
    })
  }

  static operatorWritingAction (subscription: any, subscriberId: string) {
    if (!subscription) return

    subscription.send({
      action: ActionCableCommands.OPERATOR_START_WRITING,
      recipient_id: subscriberId
    })
  }

  static operatorFinishedWritingAction (subscription: any, subscriberId: string) {
    if (!subscription) return

    subscription.send({
      action: ActionCableCommands.OPERATOR_FINISHED_WRITING,
      recipient_id: subscriberId
    })
  }
}
