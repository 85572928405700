import BaseService from './BaseService'
import { httpClient } from '@/api/httpClient'
import UrlService from './UrlService'

export default class FaqService extends BaseService {
  static async create (data) {
    return httpClient.post('/protected/faqs', data)
  }

  static async update (id, data) {
    return httpClient.put(`/protected/faqs/${id}`, data)
  }

  static async delete (id) {
    return httpClient.delete(`/protected/faqs/${id}`)
  }

  static async getFaqs (payload, payloadQ) {
    const filters = new URLSearchParams()
    filters.set('page', payload.page)
    filters.set('per_page', payload.per_page)

    const q = UrlService.getFiltersQuery(payloadQ)

    const data = await httpClient.get(`/protected/faqs?${filters}&${q}`)

    return data
  }
}
