import React, { Component } from 'react'
import { Input, Dropdown, Icon } from 'semantic-ui-react'
import InputMask from 'react-input-mask'

const DEFAULT_COUNTRY_CODE = '+7'
const countryCodeOptions = [
  { value: '+7', text: '+7', flag: 'ru' },
  { value: '+370', text: '+370', flag: 'by' },
  { value: '+380', text: '+380', flag: 'ua' }
]

export default class PhoneInput extends Component {
  state = {
    value: '',
    countryCode: DEFAULT_COUNTRY_CODE
  }

  static defaultProps = {
    name: '',
    value: '',
    mask: '(999) 999-999',
    placeholder: '(___) ___-___',
    unmaskRegexp: /[()\-_ ]+/g,
    phoneLength: 9,
    onChange: () => {},
    autoFocus: false,
    disabled: false
  }

  static getDerivedStateFromProps (nextProps) {
    const { value, countryCode } = nextProps

    if (countryCode) {
      return {
        countryCode,
        value: value.replace(countryCode, '')
      }
    }

    for (const item of countryCodeOptions) {
      if (value.startsWith(item.value)) {
        return {
          countryCode: item.value,
          value: value.replace(item.value, '')
        }
      }
    }

    return {}
  }

  unmaskPhone = (phone = this.state.value, countryCode = this.state.countryCode) => {
    const { unmaskRegexp } = this.props

    return `${countryCode}${phone.replace(unmaskRegexp, '')}`
  }

  validatePhone = () => {
    const { value, countryCode } = this.state
    const { phoneLength } = this.props

    return (this.unmaskPhone(value).length === (+phoneLength + countryCode.length))
  }

  handleClearClick = () => {
    const { name, onChange } = this.props

    onChange({ target: { name, value: '' } })
  }

  handleInputChange = ({ target: { name, value } }) => {
    const { onChange, countryCode: fixedCountryCode } = this.props
    let resValue = this.unmaskPhone(value)

    if (fixedCountryCode) {
      resValue = this.unmaskPhone(value, '')
    }

    onChange({ target: { name, value: resValue } })
  }

  handleCountryCodeChange = (event, { value: countryCode }) => {
    const { name, onChange } = this.props

    onChange({ target: { name, value: this.unmaskPhone(undefined, countryCode) } })
  }

  render () {
    const { name, mask, placeholder, autoFocus, disabled, countryCode: fixedCountryCode } = this.props
    const { value, countryCode } = this.state
    let renderedLabel = (
      <Dropdown
        value={countryCode}
        options={countryCodeOptions}
        onChange={this.handleCountryCodeChange}
      />
    )

    if (fixedCountryCode) {
      renderedLabel = countryCode
    }

    return (
      <InputMask
        name={name}
        mask={mask}
        placeholder={placeholder}
        value={value}
        onChange={this.handleInputChange}
        autoFocus={autoFocus}
        disabled={disabled}
      >
        {inputProps => (
          <Input
            {...inputProps}
            label={renderedLabel}
            icon={(
              <Icon
                link
                name='remove circle'
                color='red'
                onClick={this.handleClearClick}
                title='Удалить'
              />
            )}
          />
        )}
      </InputMask>
    )
  }
}
