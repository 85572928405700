export default (value, searchFields, q, suffix = 'cont') => {
  const searchValues = value.split(' ')

  if (searchValues.length > 1) {
    for (let i = 0; i <= searchValues.length; i++) {
      q[`${searchFields[i]}_${suffix}`] = searchValues[i]
    }
  } else {
    let searchKey = ''

    for (let i = 0; i <= searchFields.length; i++) {
      if (i === searchFields.length) break

      if (i === searchFields.length - 1) {
        searchKey += `${searchFields[i]}`
        continue
      }

      searchKey += `${searchFields[i]}_or_`
    }

    q[`${searchKey}_${suffix}`] = value
  }

  return q
}
