import React, { Component } from 'react'
import { Button, Modal } from 'semantic-ui-react'

export default class Confirm extends Component {
  static defaultProps = {
    isOpened: false,
    trigger: null,
    size: null,
    header: null,
    content: null,
    confirmButtonText: 'ОК',
    cancelButtonText: 'Отменить',
    withoutCancelButton: false,
    onConfirm: () => {},
    onCancel: () => {},
    onClose: () => {}
  }

  static getDerivedStateFromProps (nextProps) {
    const { isOpened, trigger } = nextProps

    return !trigger ? { isOpened } : {}
  }

  state = {
    isOpened: false
  }

  handleOpen = () => {
    this.setState({ isOpened: true })
  }

  handleClose = () => {
    this.props.onClose()
    this.setState({ isOpened: false })
  }

  handleConfirm = () => {
    this.props.onConfirm()
    this.handleClose()
  }

  handleCancel = () => {
    this.props.onCancel()
    this.handleClose()
  }

  renderActions = () => {
    const { confirmButtonText, cancelButtonText, withoutCancelButton } = this.props
    let renderedCancelButton = null

    if (!withoutCancelButton) {
      renderedCancelButton = (
        <Button
          key='cancel'
          content={cancelButtonText}
          onClick={this.handleCancel}
        />
      )
    }

    return [
      <Button
        key='confirm'
        color='green'
        content={confirmButtonText}
        onClick={this.handleConfirm}
      />,
      renderedCancelButton
    ]
  }

  render () {
    const { isOpened } = this.state
    const { trigger, size, header, content } = this.props

    return (
      <Modal
        open={isOpened}
        trigger={trigger}
        size={size}
        closeOnEscape={false}
        closeOnDimmerClick={false}
        onOpen={this.handleOpen}
        onClose={this.handleClose}
      >
        <Modal.Header>{header}</Modal.Header>
        <Modal.Content>{content}</Modal.Content>
        <Modal.Actions>{this.renderActions()}</Modal.Actions>
      </Modal>
    )
  }
}
