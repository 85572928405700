import React, { Component } from 'react'
import { Card } from 'semantic-ui-react'

import './Message.scss'

export default class MessageExample extends Component {
  static defaultProps = {
    text: null,
    fluid: false,
    disabled: false
  }

  render () {
    return (
      <Card fluid={this.props.fluid} className="unselected" style={{ border: '1px solid #AAD5DE', backgroundColor: '#F7FFFF' }}>
        <Card.Content>
          <Card.Description>
            {this.props.text}
          </Card.Description>
          <Card.Meta textAlign="right">12:10</Card.Meta>
        </Card.Content>
      </Card>
    )
  }
}
