type Type = {
  sent: {
    name: 'Отправлено',
    value: 'sent',
    colorName: 'violet',
    color: '#5A42C0',
    queryParamValue: 'sent'
  },
  inQueue: {
    name: 'В очереди',
    value: 'pending',
    colorName: 'teal',
    color: '#51B2AC',
    queryParamValue: 'pending'
  },
  undelivered: {
    name: 'Не доставлено',
    value: 'undelivered',
    colorName: 'grey',
    color: '#767676',
    queryParamValue: 'undelivered'
  }
}

const status: Type = {
  sent: {
    name: 'Отправлено',
    value: 'sent',
    colorName: 'violet',
    color: '#5A42C0',
    queryParamValue: 'sent'
  },
  inQueue: {
    name: 'В очереди',
    value: 'pending',
    colorName: 'teal',
    color: '#51B2AC',
    queryParamValue: 'pending'
  },
  undelivered: {
    name: 'Не доставлено',
    value: 'undelivered',
    colorName: 'grey',
    color: '#767676',
    queryParamValue: 'undelivered'
  }
}

export default status
