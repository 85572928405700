import React from 'react'
import { Image, Button } from 'semantic-ui-react'

import expiredImage from '../../images/expired.png'
import './ErrorPage.scss'

export default class ErrorPage extends React.Component {
  static defaultProps = {
    text: '',
    goBack: () => {}
  }

  render () {
    const { text } = this.props
    let renderedImage = null
    let renderedGoBackButton = null

    if (!this.props.withoutImage) {
      renderedImage = (<Image className='error-page__image' src={expiredImage} />)
    }

    if (this.props.withGoBackButton) {
      renderedGoBackButton = (
        <div className='error-page__go-back-button-wrapper'>
          <Button
            onClick={() => this.props.goBack()}
            content='Вернуться назад'
            fluid
          />
        </div>
      )
    }

    return (
      <div className='error-page'>
        <div className='error-page__content'>
          {renderedImage}

          <div className='error-page__text'>{text}</div>
        </div>

        {renderedGoBackButton}
      </div>
    )
  }
}
