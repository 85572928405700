import React, { MouseEvent, Suspense, useState, useEffect } from 'react'
import { Sidebar, StrictSidebarPusherProps } from 'semantic-ui-react'
import MobileDetect from 'mobile-detect'
import ActionCable from 'action-cable-react-jwt'
import { Navigate, Outlet, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import './styles/index.scss'

import Config from 'Config'
import { AlertWrapper, SiteHeader, MainMenu, Loading } from '@/components'
import { UserService, NotificationsService, SettingsService } from '@/services'
import { ActionCableChannels } from '@/constants/action-cable'
import { setSidebarVisible } from '@/store/layoutSlice'
import { setChildrenEnabled, setChatbotGroupId, setRoutingKind, setDepositDisabled, setDeliveryEnabled } from '@/store/settingsSlice'
import { setUser } from '@/store/profileSlice'
import { Sentry } from '@/plugins/sentry'
import { User } from '@/models/entity'

const SERVER_ERROR_MESSAGE = 'Ошибка сервера, попробуйте позже или свяжитесь с техподдержкой'

const WS = ActionCable.createConsumer(`${Config.serverUrl}/cable`, UserService.authToken)

const md = new MobileDetect(window.navigator.userAgent)
const mdIsMobile = md.mobile()

const Main = () => {
  let subscription: any
  const dispatch = useDispatch()
  const navigate = useNavigate()

  const [ userData, setUserData ] = useState<User | null>(null)
  const [ availableChatbotGroups, setAvailableChatbotGroups ] = useState([])
  const [ activeChatbotGroupId, setActiveChatbotGroupId ] = useState(null)

  const [ userLoaded, setUserLoaded ] = useState(false)

  const [ isMobile, setIsMobile ] = useState(!!mdIsMobile)
  const [ isSidebarOpen, setIsSidebarOpen ] = useState(!mdIsMobile)

  const [ unreadCounter, setUnreadCounter ] = useState(0)
  const [ unseenGuests, setUnseenGuests ] = useState(0)

  const updateDialog = (data: any) => {
    if ('unread_messages_count' in data.content) {
      setUnreadCounter(data.content.unread_messages_count)
    }

    if ('unseen_guests' in data.content) {
      setUnseenGuests(data.content.unseen_guests)
    }
  }

  const toggleSidebarVisibility = () => setIsSidebarOpen(!isSidebarOpen)

  const loadUserData = async () => {
    try {
      const newUserData = await UserService.getMe()
      const { phone } = newUserData

      dispatch(setUser(newUserData))

      if (newUserData.have_app) {
        localStorage.setItem('haveApp', newUserData.have_app.toString())
      } else {
        localStorage.removeItem('haveApp')
      }

      setUserData(newUserData)
      setUserLoaded(true)
      setUnreadCounter(newUserData.unread_counter)
      setUnseenGuests(newUserData.unseen_guests)

      Sentry.setUser({ id: `[LC USER]: ${phone}`, username: `[LC USER]: ${phone}` })
    } catch (error) {
      NotificationsService.error(SERVER_ERROR_MESSAGE)
      throw error
    }
  }

  const loadChatbotGroups = async () => {
    try {
      const settings: any = await SettingsService.getSettings()

      dispatch(setChildrenEnabled(settings.children_enabled))
      dispatch(setChatbotGroupId(settings.id))
      dispatch(setRoutingKind(settings.routing_kind))
      dispatch(setDepositDisabled(settings.disable_deposit))
      dispatch(setDeliveryEnabled(settings.delivery_promotions_enabled))

      setAvailableChatbotGroups(settings.available_chatbot_groups)
      setActiveChatbotGroupId(settings.id)

      const managersCitiesString = settings.cities_without_marketing_managers.reduce((sum: string, current: string) => !sum ? current : sum + ', ' + current, '')
      const operatorsCitiesString = settings.cities_without_operators.reduce((sum: string, current: string) => !sum ? current : sum + ', ' + current, '')

      if (settings.routing_kind === 'default' && settings.cities_without_operators.length) {
        NotificationsService.error('Добавьте оператора, чтобы не оставлять ни одного сообщения от подписчиков без ответа')
      } else if (operatorsCitiesString) {
        NotificationsService.error(`Добавьте оператора, чтобы не оставлять ни одного сообщения от подписчиков без ответа, в города: ${operatorsCitiesString}`)
      }
      if (settings.routing_kind !== 'default' && managersCitiesString) NotificationsService.error(`Добавьте маркетолога, чтобы не оставлять ни одного сообщения от подписчиков без ответа, в города: ${managersCitiesString}`)
      if (!settings.has_not_expired_iiko_delivery_token) NotificationsService.error('ВНИМАНИЕ Лицензия iiko delivery недоступна, система временно не работает. Оплатите лицензию в iiko для возобновления работы системы')
    } catch (error) {
      NotificationsService.error(SERVER_ERROR_MESSAGE)
      throw error
    }
  }

  useEffect(() => {
    if (UserService.isAuthenticated) {
      loadUserData()
      loadChatbotGroups()
      dispatch(setSidebarVisible(isSidebarOpen))
      subscription = WS.subscriptions.create(
        { channel: ActionCableChannels.NOTIFICATIONS_COUNTER },
        {
          connected: () => {},
          disconnected: () => {},
          received: (data: any) => updateDialog(data)
        }
      )

      if (Config.showZendesk) {
        const script = document.createElement('script')

        script.src = 'https://static.zdassets.com/ekr/snippet.js?key=0d7b28ca-08f9-40c2-889e-7722657f3aee'

        script.id = 'ze-snippet'

        document.body.appendChild(script)
      }
    } else {
      navigate('/auth')
    }
  }, [])

  useEffect(() => {
    dispatch(setSidebarVisible(isSidebarOpen))
  }, [ isSidebarOpen ])

  const getRenderedContent = () => {
    const user = userData || {}
    const fullWidthClass = isSidebarOpen ? '' : 'full-width'
    const sidebarPusherProps: StrictSidebarPusherProps & { onClick?(e: MouseEvent<HTMLElement>): void } = {}

    if (isMobile && isSidebarOpen) {
      sidebarPusherProps.dimmed = true
      sidebarPusherProps.onClick = () => setIsSidebarOpen(false)
    }

    return (
      <Sidebar.Pushable>
        <AlertWrapper className={`fixed-alerts ${fullWidthClass}`} />

        <MainMenu
          visible={isSidebarOpen}
          onHide={() => setIsSidebarOpen(false)}
          unreadCounter={unreadCounter}
          unseenGuests={unseenGuests}
        />

        <Sidebar.Pusher className={`page-content ${fullWidthClass}`} {...sidebarPusherProps}>
          <SiteHeader
            sliderToggle={() => toggleSidebarVisibility()}
            availableChatbotGroups={availableChatbotGroups}
            userData={user}
            activeChatbotGroupId={activeChatbotGroupId}
          />

          <Suspense fallback={<div></div>}>
            <Outlet />
          </Suspense>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
    )
  }

  return (
    !userLoaded
      ? <AlertWrapper className="loading-center">
        <Loading />
      </AlertWrapper>
      : getRenderedContent()
  )
}

export default Main
