import { Orders } from '@/models/entity/orders/Orders'
import { OrdersMapped } from '@/models/entity/orders/OrdersMapped'
import { OrdersSubscriber } from '@/models/entity/orders/OrdersSubscriber'
import { OrdersSubscriberMapped } from '@/models/entity/orders/OrdersSubscriberMapped'
import { ReviewQuestion } from '@/models/entity/reviewQuestion/ReviewQuestion'
import { ReviewQuestionMapped } from '@/models/entity/reviewQuestion/ReviewQuestionMapped'
import { mapDtoToIikoOrderItem } from './iiko-mappers'

export const mapDtoToOrder = (dto: Orders): OrdersMapped => {
  return ({
    id: dto.id,
    items: dto.items ? dto.items.map(mapDtoToIikoOrderItem) : [],
    review: dto.review && mapDtoToOrderReview(dto.review),
    amount: dto.amount ?? 0,
    amountWoBonuses: dto.amount_wo_bonuses ?? 0,
    avgRating: dto.avg_rating ?? '0.0',
    accruedBonuses: dto.accrued_bonuses ?? '0.0',
    deductedBonuses: dto.deducted_bonuses ?? '0.0',
    accruedDeposit: dto.accrued_deposit ?? 0,
    deductedDeposit: dto.deducted_deposit ?? 0,
    canReview: dto.can_operator_create_review,
    lastStatus: dto.last_status,
    createdAt: dto.created_at && new Date(dto.created_at),
    updatedAt: dto.updated_at && new Date(dto.updated_at),
    fullAmount: dto.full_amount ?? 0,
    guestsCount: dto.guests_count ?? 0,
    iikoCreatedAt: dto.iiko_created_at,
    iikoOrderId: dto.iiko_order_id,
    iikoOrderNumber: dto.iiko_order_number,
    isBanquet: dto.is_banquet,
    isDelivery: dto.is_delivery,
    isSelfDelivery: dto.is_self_delivery,
    phone: dto.phone ?? null,
    subscriber: dto.subscriber && mapDtoToOrderSubscriber(dto.subscriber),
    restaurant: dto.restaurant && mapDtoToRestaurant(dto.restaurant),
    canOperatorCreateReview: dto.can_operator_create_review
  })
}

export const mapDtoToQuestion = (dto: ReviewQuestion): ReviewQuestionMapped => ({
  id: dto.id,
  active: dto.active,
  isDelivery: dto.is_delivery,
  type: dto.kind,
  text: dto.question,
  createdAt: dto.created_at,
  updatedAt: dto.updated_at
})

const mapDtoToRestaurant = (dto: any) => ({
  id: dto.id,
  name: dto.name && typeof dto.name === 'string' && dto.name.trim(),
  phone: dto.phone,
  phoneDelivery: dto.phone_delivery,
  icon: {
    id: dto.id,
    full: dto.full,
    thumb: dto.thumb
  }
})

const mapDtoToOrderReview = (dto: any) => ({
  id: dto.id,
  comment: dto.comment,
  source: dto.source,
  createdAt: dto.created_at && new Date(dto.created_at),
  updatedAt: dto.updated_at && new Date(dto.updated_at)
})

const mapDtoToOrderSubscriber = (dto: OrdersSubscriber): OrdersSubscriberMapped => ({
  id: dto.id ?? '',
  lastName: dto.last_name ?? '',
  firstName: dto.first_name ?? '',
  middleName: dto.middle_name ?? '',
  fullName: [ dto.last_name, dto.first_name, dto.middle_name ].join(' '),
  phone: dto.phone ?? ''
})
