import { purifyObject } from '@/utils'
import { mapDtoToOrder } from '@/utils/mappers/order-mappers'
import BaseService from './BaseService'
import UrlService from './UrlService'
import { httpClient } from '@/api/httpClient'
import { Orders } from '@/models/entity/orders/Orders'

type Query = {
  can_operator_create_review_eq?: 1 | null | undefined
  id_or_iiko_order_number_or_subscriber_first_name_or_subscriber_last_name_cont: string
  is_delivery_in?: boolean[] | null
  subscriber_id_not_null?: 1 | null | undefined
} | {}
export default class KanbanService extends BaseService {
  static async getOrders (restaurantId: string, query: Query) {
    const queryObject = purifyObject(query)
    const queryString = UrlService.getFiltersQuery({ ...queryObject, sorts: 'iiko_created_at DESC' })
    const { data }: { data: Orders[] } = await httpClient.get(`/protected/kanban/orders/${restaurantId}?${queryString}`)

    if (data && Array.isArray(data)) {
      return data.map(mapDtoToOrder)
    }
  }

  static async changeOrderStatus (orderId: string, resultStatus: string) {
    const { data }: { data: Orders } = await httpClient.post(`/protected/orders/${orderId}/status`, { status: resultStatus })
    const order = mapDtoToOrder(data)

    return order
  }
}
