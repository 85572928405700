export enum ValueTypes {
  subscribe = 'subscribe',
  sum = 'check_amount',
  daysFromLastVisit = 'days_last_visit',
  daysFromLastVisitAndMore = 'days_or_more_last_visit',
  birthday = 'birthday',
  childBirthday = 'child_birthday',
  addGuestToIikoCards = 'add_to_category',
  noOrderSinceSubscription = 'no_order_after_subscription',
}

const config = {
  subscribe: {
    value: ValueTypes.subscribe,
    text: 'Подписался на чат-бот / приложение',
    tableViewText: 'Подписка'
  },
  sum: {
    value: ValueTypes.sum,
    text: 'Сумма в заказе',
    tableViewText: 'Сумма от'
  },
  daysFromLastVisit: {
    value: ValueTypes.daysFromLastVisit,
    text: 'Не был в ресторане Х дней',
    tableViewText: 'Не был Х дней'
  },
  daysFromLastVisitAndMore: {
    value: ValueTypes.daysFromLastVisitAndMore,
    text: 'Не был в ресторане Х дней и больше',
    tableViewText: 'Не был Х дней и больше'
  },
  birthday: {
    value: ValueTypes.birthday,
    text: 'День Рождения',
    tableViewText: 'День Рождения'
  },
  childBirthday: {
    value: ValueTypes.childBirthday,
    text: 'День Рождения ребенка',
    tableViewText: 'День Рождения ребенка'
  },
  addGuestToIikoCards: {
    value: ValueTypes.addGuestToIikoCards,
    text: 'Добавление гостя в категорию iiko cards',
    tableViewText: 'Категория\niikocards'
  },
  noOrderSinceSubscription: {
    value: ValueTypes.noOrderSinceSubscription,
    text: 'Не делал заказ Х дней после подписки',
    tableViewText: 'Не делал заказ\nХ дней'
  }
}

export default config
