import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Modal, Loader, Radio } from 'semantic-ui-react'

import './styles.scss'

import { SettingsService } from '../../services'
import { AppModal } from '..'
import { TemplateMapped } from '@/models/entity/template/TemplateMapped'

type Props = {
  onSelect: (text: string) => void,
  triggerClassName: string
}

const QuickAnswersDialog: React.FC<Props> = (props) => {
  const { triggerClassName = '', onSelect = () => {} } = props

  //
  // State
  //

  const [ isOpened, setIsOpened ] = useState(false)
  const [ searchingTerm, setSearchingTerm ] = useState('')
  const [ isLoading, setIsLoading ] = useState(true)
  const [ templates, setTemplates ] = useState<TemplateMapped[]>([])
  const [ selectedTemplate, setSelectedTemplate ] = useState<string | null>(null)
  const [ templatesToRender, setTemplatesToRender ] = useState<TemplateMapped[]>([])
  const [ hasUnsavedChanges, setHasUnsavedChanges ] = useState(false)

  //
  // Methods
  //

  const loadQuestions = async () => {
    try {
      setIsLoading(true)
      const templates = await SettingsService.getMessagesTemplates()
      setTemplates(templates)
    } finally {
      setIsLoading(false)
    }
  }

  const handleClose = () => {
    setSelectedTemplate(null)
    setIsOpened(false)
    setSearchingTerm('')
  }

  const handleSelect = () => {
    const template = templates.find((template) => template.id === selectedTemplate)

    if (template) {
      onSelect(template.text)
    } else {
      onSelect('')
    }

    handleClose()
  }

  //
  // Effects
  //

  useEffect(() => {
    loadQuestions()
  }, [])

  useEffect(() => {
    if (searchingTerm.trim()) {
      const newTemplatesToRender = templates.filter((template) =>
        template.title.toLowerCase().includes(searchingTerm.toLowerCase())
      )

      setTemplatesToRender(newTemplatesToRender)
    } else {
      setTemplatesToRender(templates)
    }
  }, [ searchingTerm, templates ])

  //
  // Render
  //

  const renderTemplates = !isLoading ? (
    <Form className='quick-answers-dialog__templates'>
      {templatesToRender.map((template) => (
        <div
          key={template.id}
          className="quick-answers-dialog__template"
        >
          <Form.Field className="template">
            <Radio
              name='template'
              value={template.id}
              checked={selectedTemplate === template.id}
              onChange={(_event, { value }) => {
                value && setSelectedTemplate(value.toString())
                setHasUnsavedChanges(true)
              }}
              label={(
                <label>
                  <span className="template__title">{template.title}</span>
                  <p>{template.text}</p>
                </label>
              )}
            />
          </Form.Field>
        </div>
      ))}
    </Form>
  ) : <Loader />

  return (
    <AppModal
      centered={false}
      className="quick-answers-dialog"
      size="small"
      open={isOpened}
      onClose={handleClose}
      shouldConfirmClose={hasUnsavedChanges}
      trigger={
        <Button
          className={triggerClassName}
          type="button"
          onClick={() => setIsOpened(true)}
          loading={isLoading}
          content="Быстрые ответы"
          disabled={templates.length === 0}
          color='blue'
        />
      }
    >
      <Modal.Header>
        <div className="quick-answers-dialog__header">
          <h3>Быстрые ответы</h3>
          <Input
            size="mini"
            value={searchingTerm}
            placeholder='Поиск...'
            onChange={event => setSearchingTerm(event.target.value)}
          />
        </div>
      </Modal.Header>
      <Modal.Content>
        {renderTemplates}
      </Modal.Content>
      <Modal.Actions>
        <Button
          onClick={handleSelect}
          color='green'
          content="Добавить"
          type="button"
        />
        <Button
          onClick={handleClose}
          content="Отменить"
          type="button"
        />
      </Modal.Actions>
    </AppModal>
  )
}

export default QuickAnswersDialog
