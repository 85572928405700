import React from 'react'
import { Dropdown, Icon } from 'semantic-ui-react'
import MobileDetect from 'mobile-detect'

import './HoveringDropdownMenu.scss'

export default class HoveringDropdownMenu extends React.PureComponent {
  state = {
    open: false
  }

  static defaultProps = {
    onChangeState: () => {}
  };

  constructor (props) {
    super(props)

    const md = new MobileDetect(window.navigator.userAgent)

    this.state.isPhone = md.mobile() && !md.tablet()
  }

  onOpen = () => {
    this.setState({ open: true })
    this.props.onChangeState(true)
  }

  onClose = () => {
    this.setState({ open: false })
    this.props.onChangeState(false)
  }

  render () {
    const { open, isPhone } = this.state
    const icon = open ? 'close' : 'bars'
    const dropdownProps = {
      pointing: 'right'
    }
    let renderedIcon = (<Icon name={icon} />)
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth

    if (isPhone && (width <= 768)) {
      dropdownProps.fluid = true
      dropdownProps.pointing = 'top center'
      renderedIcon = (<div className='hovering-dropdown-menu__icon-wrapper'>{renderedIcon}</div>)
    }

    return (
      <Dropdown
        open={open}
        className='hovering-dropdown-menu green'
        button
        icon={renderedIcon}
        onClose={this.onClose}
        onClick={this.onOpen}
        {...dropdownProps}
      >
        <Dropdown.Menu>
          {this.props.children}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}
