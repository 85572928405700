import { AxiosResponse } from 'axios'

import BaseService from '../BaseService'
import { httpClient } from '@/api/httpClient'
import { PromotionBasePayload } from '.'
import { BasePromotion } from '@/models/entity/promotions/base'

export default class PromotionsService extends BaseService {
  static async create (payload: PromotionBasePayload) {
    return httpClient.post('/protected/promotions/base', payload)
  }

  static async save (id: string, data: PromotionBasePayload) {
    return httpClient.patch(`/protected/promotions/base/${id}`, data)
  }

  static async delete (id: string) {
    return httpClient.delete(`/protected/promotions/base/${id}`)
  }

  static async getBasePromotions (payload: { page: number, per_page: number }) {
    const filters = new URLSearchParams()
    filters.set('page', payload.page.toString())
    filters.set('per_page', payload.per_page.toString())

    const data = await httpClient.get<never, AxiosResponse<BasePromotion[]>>(`/protected/promotions/base?${filters}`)

    return data
  }
}
