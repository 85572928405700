import { AxiosResponse } from 'axios'

import BaseService from './BaseService'
import { UrlService } from '@/services'
import { mapDtoToOrder } from '@/utils/mappers/order-mappers'
import { httpClient } from '@/api/httpClient'

export default class SubscriberService extends BaseService {
  static async block (subscriberId: string | undefined): Promise<{ success: string }> {
    return httpClient.get(`/protected/subscribers/${subscriberId}/block`)
  }

  static async unblock (subscriberId: any) {
    return httpClient.get(`/protected/subscribers/${subscriberId}/unblock`)
  }

  static async addTag (subscriberId: any, tags: any) {
    return httpClient.post(`/protected/subscribers/${subscriberId}/set_tag`, { tags })
  }

  static async getTags (subscriberId: any) {
    const { data } = await httpClient.get(`/protected/subscribers/${subscriberId}/tags`)

    return data
  }

  static async getRoleData (subscriberId: string) {
    type Response = {
      cities: {
        id: string
        name: string
        timezone: string
      }[]
      id: string
      kind: string
      receive_all_messages: boolean
      restaurants: {
        id: string,
        name: string,
        icon: string,
        phone: string,
        phone_delivery: string
      }[]
    }[]

    const { data } = await httpClient.get<null, AxiosResponse<Response>>(`protected/subscribers/${subscriberId}/subscriber_roles`)

    return data[0]
  }

  static async setRole (subscriberId: string, values: any) {
    return httpClient.post(`/protected/subscribers/${subscriberId}/subscriber_roles`, values)
  }

  static async unsetRole (subscriberId: string) {
    return httpClient.delete(`/protected/subscribers/${subscriberId}/subscriber_roles`)
  }

  static async updateRole (subscriberId: string, roleId: string, payload: { city_ids: string[], kind: string, receive_all_messages?: boolean, restaurant_ids?: string[] }) {
    return httpClient.put(`/protected/subscribers/${subscriberId}/subscriber_roles/${roleId}`, payload)
  }

  static async updateSubscriberCategory (data: any) {
    return httpClient.post(`/protected/subscriber_categories/${data.id}`, data)
  }

  static async deleteComment (subscriberId: any, commentId: any) {
    return httpClient.delete(`/protected/subscribers/${subscriberId}/comments/${commentId}`)
  }

  static async getInfo (subscriberId: any) {
    const { data } = await httpClient.get(`/protected/subscribers/${subscriberId}/details`)

    return data
  }

  static async getOrderDetails (orderId: any) {
    const { data } = await httpClient.get(`/protected/orders/${orderId}/detail`)
    const order = mapDtoToOrder(data)

    return order
  }

  static async createOrderReview (orderId: any, { reviews, comment, doNotCall }: any) {
    const payload = {
      comment,
      subscriber_reviews: reviews,
      do_not_contact_for_thirty_days: doNotCall
    }

    return httpClient.post(`/protected/orders/${orderId}/review`, payload)
  }

  static async getBalanceAmountByWalletType (subscriberId: any, walletType: any) {
    const q = UrlService.getFiltersQuery({ sorts: 'external_created_at DESC', wallet_type_eq: walletType })
    const { data }: any = await httpClient.get(`/protected/subscribers/${subscriberId}/details/balance?per_page=1&page=1&${q}`)

    if (!data.length || data.length === 0) return 0

    return data[0].balance
  }

  static async getSubsribersList (page: number, itemsPerPage: number, payloadQ: any) {
    const q = UrlService.getFiltersQuery(payloadQ)

    const data = await httpClient.get(`/protected/subscribers/list?page=${page}&per_page=${itemsPerPage}&${q}`)

    return data
  }

  static async getSubscriberOrdersHistory (subscriberId: string, payload: { page: number, per_page: number }, payloadQ: { sorts: string, iiko_order_number_cont?: string }) {
    const filters = new URLSearchParams()
    filters.set('page', payload.page.toString())
    filters.set('per_page', payload.per_page.toString())

    const q = UrlService.getFiltersQuery(payloadQ)

    const data = await httpClient.get(`/protected/subscribers/${subscriberId}/orders?${filters}&${q}`)

    return data
  }

  static async getSubscriberBalanceHistory (subscriberId: string, payload: { page: number, per_page: number }, payloadQ: { sorts: string, wallet_type_eq: string }) {
    const filters = new URLSearchParams()
    filters.set('page', payload.page.toString())
    filters.set('per_page', payload.per_page.toString())

    const q = UrlService.getFiltersQuery(payloadQ)

    const data = await httpClient.get(`/protected/subscribers/${subscriberId}/details/balance?${filters}&${q}`)

    return data
  }
}
