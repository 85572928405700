import React, { lazy } from 'react'
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom'

import Main from '../Main'

const AuthPage = lazy(() => import('@/pages/AuthPage/AuthPage'))
const SettingsPage = lazy(() => import('@/pages/SettingsPage/SettingsPage'))
const ProfilePage = lazy(() => import('@/pages/SettingsPage/ProfilePage'))
const ChatPage = lazy(() => import('@/pages/ChatPage'))
const SubscribersPage = lazy(() => import('@/pages/SubscribersPage/SubscribersPage'))
const BulksPage = lazy(() => import('@/pages/BulksPage/BulksPage'))
const BulkReportPage = lazy(() => import('@/pages/BulkReportPage'))
const ChatPageMessages = lazy(() => import('@/pages/ChatPage/ChatPageMessages'))
const RestaurantPromoPage = lazy(() => import('@/pages/PromoPage/RestaurantPromoPage'))
const BasicSettings = lazy(() => import('@/pages/SettingsPage/BasicSettings'))
const FaqsPage = lazy(() => import('@/pages/FaqsPage/FaqsPage'))
const ChatMessageTemplates = lazy(() => import('@/pages/SettingsPage/ChatMessageTemplates'))
const SubscriberCategories = lazy(() => import('@/pages/SettingsPage/SubscriberCategories'))
const ReviewQuestionsPage = lazy(() => import('@/pages/ReviewQuestionsPage/ReviewQuestionsPage'))
const BulksSettings = lazy(() => import('@/pages/SettingsPage/BulksSettings/BulksSettings'))
const ChatbotTextes = lazy(() => import('@/pages/SettingsPage/ChatbotTextes'))
const BookingSettings = lazy(() => import('@/pages/SettingsPage/BookingSettings'))
const ChildrenPage = lazy(() => import('@/pages/SubscribersPage/SubscriberChildren'))
const MenuSettings = lazy(() => import('@/pages/SettingsPage/MenuSettings'))
const PoliciesPage = lazy(() => import('@/pages/PoliciesPage/PoliciesPage'))
const WaitersPage = lazy(() => import('@/pages/WaitersPage/WaitersPage'))
const WaiterCreatingPage = lazy(() => import('@/pages/WaitersPage/WaiterCreatingPage'))
const WaitersRatingPage = lazy(() => import('@/pages/WaitersPage/WaitersRatingPage'))
const BonusRulesPage = lazy(() => import('@/pages/BonusRulesPage/BonusRulesPage'))
const OrdersTabPage = lazy(() => import('@/pages/OrdersPage/OrdersTabPage'))
const PromotionsBasePage = lazy(() => import('@/pages/PromotionsPage/PromotionsBasePage'))
const PromotionsTriggerPage = lazy(() => import('@/pages/PromotionsPage/PromotionsTriggerPage'))
const PromotionTriggerDetailsPage = lazy(() => import('@/pages/PromotionsPage/PromotionTriggerDetailsPage'))
const ReviewsPage = lazy(() => import('@/pages/ReviewsPage/ReviewsPage'))
const RestaurantsPage = lazy(() => import('@/pages/RestaurantsPage/RestaurantsPage'))
const SubscriberDetails = lazy(() => import('@/pages/SubscribersPage/SubscriberDetails'))
const SubscriberBalanceHistory = lazy(() => import('@/pages/SubscribersPage/SubscriberBalanceHistory/SubscriberBalanceHistory'))
const SubscriberOrdersHistory = lazy(() => import('@/pages/SubscribersPage/SubscriberOrdersHistory'))
const SubscriberChildren = lazy(() => import('@/pages/SubscribersPage/SubscriberChildren'))
const LinksPage = lazy(() => import('@/pages/Marketing/LinksPage'))
const LinkDetailsPage = lazy(() => import('@/pages/Marketing/LinkDetailsPage'))
const OrderReviewPage = lazy(() => import('@/pages/OrderReviewPage'))
const PollsTablePage = lazy(() => import('@/pages/Polls/PollsTablePage'))
const CreatePollPage = lazy(() => import('@/pages/Polls/CreatePollPage'))
const PollReportPage = lazy(() => import('@/pages/Polls/PollReportPage'))
const RepeatPollPage = lazy(() => import('@/pages/Polls/RepeatPollPage'))
const EditPollPage = lazy(() => import('@/pages/Polls/EditPollPage'))

class RouteConfig extends React.PureComponent {
  render () {
    return (
      <Router>
        <Routes>
          <Route path='/auth' element={<AuthPage />} />
          <Route path='/join_us/:restaurant_id' element={<RestaurantPromoPage />} />
          <Route path='/review/:review_id' element={<OrderReviewPage />} />
          <Route element={<Main />}>
            <Route path='chats' element={<ChatPage />}>
              <Route path=':dialogId' element={<ChatPageMessages />} />
            </Route>
            <Route path='settings' element={<SettingsPage />} >
              <Route path='main' element={<BasicSettings />} />
              <Route path='categories' element={<SubscriberCategories />} />
              <Route path='faq' element={<FaqsPage />} />
              <Route path='bonus_rules' element={<BonusRulesPage />} />
              <Route path='policy' element={<PoliciesPage />} />
              <Route path='reviews' element={<ReviewQuestionsPage />} />
              <Route path='bulks' element={<BulksSettings />} />
              <Route path='textes' element={<ChatbotTextes />} />
              <Route path='booking' element={<BookingSettings />} />
              <Route path='chatmessagetemplates' element={<ChatMessageTemplates />} />
              <Route path='children' element={<ChildrenPage />} />
              <Route path='menu' element={<MenuSettings />} />
            </Route>
            <Route path='profile' element={<ProfilePage />} />
            <Route path='restaurants' element={<RestaurantsPage />} />
            <Route path='subscriber/:subscriberId' element={<SubscriberDetails />} >
              <Route path='balance' element={<SubscriberBalanceHistory /> } />
              <Route path='orders' element={<SubscriberOrdersHistory />} />
              <Route path='children' element={<SubscriberChildren />} />
            </Route>
            <Route path='subscribers' element={<SubscribersPage />} />
            <Route path='waiters' element={<WaitersPage />} >
              <Route path='waiters-creating' element={<WaiterCreatingPage />} />
              <Route path='waiters-rating' element={<WaitersRatingPage />} />
            </Route>
            <Route path='orders/*' element={<OrdersTabPage />} />
            <Route path='reviews' element={<ReviewsPage />} />
            <Route path='bulks' element={<BulksPage />} />
            <Route path='bulks/:id' element={<BulkReportPage />} />
            <Route path='promotions/base' element={<PromotionsBasePage />} />
            <Route path='promotions/trigger' element={<PromotionsTriggerPage />} />
            <Route path='promotions/trigger/:triggerId' element={<PromotionTriggerDetailsPage />} />
            <Route path='marketing/links' element={<LinksPage />} />
            <Route path='marketing/links/:linkId' element={<LinkDetailsPage />} />
            <Route path='polls' element={<PollsTablePage />} />
            <Route path='polls/create' element={<CreatePollPage />} />
            <Route path='polls/report/:id' element={<PollReportPage />} />
            <Route path='polls/repeat/:id' element={<RepeatPollPage />} />
            <Route path='polls/edit/:id' element={<EditPollPage />} />

            {/* Redirects */}
            <Route path='*' element={<Navigate to='/chats' replace />} />
            <Route path='subscriber' element={<Navigate to='/subscribers' replace />} />
            <Route path='subscriber/balance' element={<Navigate to='/subscribers' replace />} />
            <Route path='subscriber/orders' element={<Navigate to='/subscribers' replace />} />
            <Route path='subscriber/children' element={<Navigate to='/subscribers' replace />} />
            <Route path='polls/report' element={<Navigate to='/polls' replace />} />
            <Route path='polls/repeat' element={<Navigate to='/polls' replace />} />
            <Route path='polls/edit' element={<Navigate to='/polls' replace />} />
          </Route>
        </Routes>
      </Router>
    )
  }
}

export { RouteConfig }
