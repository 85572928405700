import BaseService from './BaseService'
import { httpClient } from '@/api/httpClient'

export default class ChatbotMessagesService extends BaseService {
  static async update (id, data) {
    return httpClient.put(`/protected/chatbotmessages/${id}`, data)
  }

  static async list () {
    const { data } = await httpClient.get('/protected/chatbotmessages')

    return data
  }

  static async getJoinUs () {
    const { data } = await httpClient.get('/protected/settings/joinus')

    return data
  }

  static async updateJoinUs (data) {
    return httpClient.put('/protected/settings/joinus', data)
  }
}
