import saveAs from 'file-saver'

import BaseService from './BaseService'
import { API_URL, httpClient } from '@/api/httpClient'
import {
  mapDtoToPollDetails,
  mapFiltersDataToApiPayload,
  mapPollDataToApiPayload
} from '@/utils/mappers/poll-mappers'
import UrlService from './UrlService'

export default class PollsService extends BaseService {
  static getPollReportEndpoint = (pollId) => `${API_URL}/protected/polls/${pollId}/report`;

  static async removePoll (pollId) {
    return httpClient.delete(`${API_URL}/protected/polls/${pollId}`)
  }

  static async completePoll (pollId) {
    return httpClient.patch(`${API_URL}/protected/polls/${pollId}`)
  }

  static async createPoll (data) {
    const payload = mapPollDataToApiPayload(data)

    return httpClient.post(`${API_URL}/protected/polls/`, payload)
  }

  static async updatePoll (id, data) {
    const payload = mapPollDataToApiPayload(data)

    return httpClient.put(`${API_URL}/protected/polls/${id}`, payload)
  }

  static async getSubsCount (filters) {
    const { data } = await httpClient.post(`${API_URL}/protected/polls/subscribers_count`, {
      filter: mapFiltersDataToApiPayload(filters)
    })

    return data
  }

  static async getPollById (id) {
    const { data } = await httpClient.get(`${API_URL}/protected/polls/${id}`)
    const poll = mapDtoToPollDetails(data)

    return poll
  }

  static async downloadPollReport (id, questionId = null) {
    const query = questionId ? `?question_id=${questionId}` : ''
    const response = await httpClient.get(`${API_URL}/protected/polls/${id}/report/download${query}`, { responseType: 'blob' })
    const fileName = this.getFileNameByContentDisposition(response.headers['content-disposition'])

    saveAs(response.data, fileName)
  }

  static async getPolls (payload, payloadQ) {
    const filters = new URLSearchParams()
    filters.set('page', payload.page)
    filters.set('per_page', payload.per_page)

    const q = UrlService.getFiltersQuery(payloadQ)

    const data = await httpClient.get(`/protected/polls?${filters}&${q}`)

    return data
  }

  static async getPollReport (pollId, payload) {
    const filters = new URLSearchParams()
    filters.set('page', payload.page)
    filters.set('per_page', payload.per_page)

    const data = await httpClient.get(`protected/polls/${pollId}/report?${filters}`)

    return data
  }
}
