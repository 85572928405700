import saveAs from 'file-saver'

import BaseService from './BaseService'
import { mapDtoToLink, mapDtoToLinkCounters } from '@/utils/mappers/marketing-mappers'
import { UrlService } from '.'
import { httpClient, API_URL } from '@/api/httpClient'

export default class MarketingService extends BaseService {
  static linksEndpoint = `${API_URL}/protected/short_links`

  static getLinkPiecesEndpoint (linkId) {
    return `${API_URL}/protected/short_links/${linkId}/grouped_events`
  }

  static async createLink ({ name, comment }) {
    return httpClient.post('/protected/short_links', { name, comment })
  }

  static async deleteLink (linkId) {
    return httpClient.delete(`/protected/short_links/${linkId}`)
  }

  static async getLinkDetails (linkId) {
    const { data } = await httpClient.get(`/protected/short_links/${linkId}`)
    const link = mapDtoToLink(data)

    return link
  }

  static async getLinkReport (linkId, query) {
    const q = UrlService.getFiltersQuery(query)
    const response = await httpClient.get(`/protected/short_links/${linkId}/export_events?${q}`, { responseType: 'blob' })
    const fileName = this.getFileNameByContentDisposition(response.headers['content-disposition'])

    saveAs(response.data, fileName)
  }

  static async getLinkCounters (linkId, query) {
    const q = UrlService.getFiltersQuery(query)
    const { data } = await httpClient.get(`/protected/short_links/${linkId}/events_counters?${q}`)
    const counters = mapDtoToLinkCounters(data)

    return counters
  }

  static async getReportShortLinks (linkId, payload, payloadQ) {
    const filters = new URLSearchParams()
    filters.set('page', payload.page)
    filters.set('per_page', payload.per_page)

    const q = UrlService.getFiltersQuery(payloadQ)

    const data = await httpClient.get(`/protected/short_links/${linkId}/grouped_events?${filters}&${q}`)

    return data
  }

  static async getShortLinks (payload, payloadQ) {
    const filters = new URLSearchParams()
    filters.set('page', payload.page)
    filters.set('per_page', payload.per_page)

    const q = UrlService.getFiltersQuery(payloadQ)

    const data = await httpClient.get(`/protected/short_links?${filters}&${q}`)

    return data
  }
}
