import { AxiosResponse } from 'axios'

import BaseService from './BaseService'
import { httpClient } from '@/api/httpClient'
import UrlService from './UrlService'
import { Orders } from '@/models/entity/orders/Orders'
import { PayloadQType } from '@/pages/OrdersPage/components/OrdersPageTable/types'
export default class OrdersService extends BaseService {
  static async getRestaurantOrders (route: string, payload: { page: number, per_page: number }, payloadQ: PayloadQType) {
    const filters = new URLSearchParams()
    filters.set('page', payload.page.toString())
    filters.set('per_page', payload.per_page.toString())

    const q = UrlService.getFiltersQuery(payloadQ)

    const data = await httpClient.get<Orders[]>(`${route}?${filters}&${q}`)

    return data
  }
}
