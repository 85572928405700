export default class BaseService {
  static getFileNameByContentDisposition (contentDisposition) {
    const regex = /filename[^;=\n]*=(UTF-8(['"]*))?(.*)/
    const matches = regex.exec(contentDisposition)
    let filename = ''

    if (matches != null && matches[3]) {
      filename = matches[3].replace(/['"]/g, '')
    }

    return decodeURI(filename)
  }
}
