export default class UrlService {
  static objectToQuery (object: { q: string }): string {
    if (typeof object !== 'object') return ''

    return Object.keys(object).map(key => `${key}=${encodeURIComponent(object[key])}`).join('&')
  }

  static getFiltersQuery (filters: object): string {
    return this.objectToQuery({ q: JSON.stringify(filters) })
  }
}
