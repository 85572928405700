import BaseService from './BaseService'
import { httpClient } from '@/api/httpClient'
import UrlService from './UrlService'

export default class SubscriberCategoriesService extends BaseService {
  static async sync () {
    return httpClient.get('/protected/subscriber_categories/sync')
  }

  static async lastSync () {
    const { data } = await httpClient.get('/protected/subscriber_categories/last_sync')

    return data
  }

  static async getSubscriberCategories (payload, payloadQ) {
    const filters = new URLSearchParams()
    filters.set('page', payload.page)
    filters.set('per_page', payload.per_page)

    const q = UrlService.getFiltersQuery(payloadQ)

    const data = await httpClient.get(`/protected/subscriber_categories?${filters}&${q}`)

    return data
  }
}
