import React, { useState, useEffect } from 'react'
import { Menu } from 'semantic-ui-react'
import { useNavigate } from 'react-router-dom'

import './PageWithTabs.scss'

import { ConditionalHidden } from '../'
import { usePrompt } from '@/utils/hooks'

const PageWithTabs = (props) => {
  const navigate = useNavigate()

  const { tabs } = props
  const [ firstTab = {} ] = tabs
  const { search, pathname } = window.location
  const currentTab = tabs.find(tab => pathname.includes(tab.name))

  const [ confirmMessage, setConfirmMessage ] = useState(props.confirm_message || '')
  const [ needConfirm, setNeedConfirm ] = useState(props.need_confirm || false)
  const [ activeItem, setActiveItem ] = useState(currentTab ? currentTab.name : firstTab.name)

  const { isActive, onCancel, onConfirm } = usePrompt(needConfirm)

  function tabIsActive (tab) {
    return activeItem === tab
  }

  const handleNeedConfirmation = (c, m) => {
    setNeedConfirm(c)
    setConfirmMessage(m)
  }

  const handleTabChange = (event, { name: tab }) => {
    const { route } = props

    let confirmed = true

    if (needConfirm) confirmed = window.confirm(confirmMessage)

    if (confirmed) {
      setActiveItem(tab)
      setNeedConfirm(false)
      setConfirmMessage('')

      navigate(`${route}/${tab}`)
    }
  }

  function renderTitles () {
    const { tabs } = props

    return tabs.map(({ name, title }, i) => (
      <Menu.Item
        key={i}
        name={name}
        active={tabIsActive(name)}
        onClick={handleTabChange}
      >
        {title}
      </Menu.Item>
    ))
  }

  function renderContent () {
    const { tabs } = props

    return tabs.map(({ name, Component }, i) => (
      <ConditionalHidden key={name} hidden={!tabIsActive(name)} withoutRender={true}>
        <Component {...props} needConfirmation={handleNeedConfirmation}/>
      </ConditionalHidden>
    ))
  }

  useEffect(() => {
    if (isActive) {
      const redirect = window.confirm(confirmMessage)

      redirect && onConfirm()
      !redirect && onCancel()
    }
  }, [ isActive ])

  return (
    <div className='page-with-tabs'>
      <Menu className='page-with-tabs__menu' fluid borderless>
        {renderTitles()}
      </Menu>

      {renderContent()}
    </div>
  )
}

export default PageWithTabs
