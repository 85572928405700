import { SemanticCOLORS } from 'semantic-ui-react'

export enum ValueTypes {
  planned = 'planned',
  active = 'active',
  finished = 'finished',
}

type ConfigItem = {
  value: ValueTypes;
  name: string;
  colorName: SemanticCOLORS;
};

const config: Record<string, ConfigItem> = {
  planned: {
    value: ValueTypes.planned,
    name: 'В плане',
    colorName: 'blue'
  },
  active: {
    value: ValueTypes.active,
    name: 'Активная',
    colorName: 'green'
  },
  finished: {
    value: ValueTypes.finished,
    name: 'Закончилась',
    colorName: 'purple'
  }
}

export default config
