import http from 'http'
import https from 'https'
import axios from 'axios'

import Config from 'Config'

import { requestInterceptor, responseInterceptorError, responseInterceptorSuccess } from './interceptors'

export const API_URL = Config.url

export const httpClient = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: 'application/json'
  },
  timeout: 60 * 1000,
  // keepAlive pools and reuses TCP connections,
  // so it's faster
  httpAgent: new http.Agent({
    keepAlive: true
  }),
  httpsAgent: new https.Agent({
    keepAlive: true
  }),
  // follow up to 10 HTTP 3xx redirects
  maxRedirects: 10
})

/**
 * Request interceptors.
 */
httpClient.interceptors.request.use(requestInterceptor)

/**
 * Response interceptors.
 */
httpClient.interceptors.response.use(responseInterceptorSuccess, responseInterceptorError)
