import React, { useState } from 'react'
import { Icon, Menu, Dropdown } from 'semantic-ui-react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import cn from 'classnames'

import './SiteHeader.scss'

import { UserService, NotificationsService, SettingsService } from '@/services'
import { Confirm } from '@/components'

const SiteHeader = ({ userData, sliderToggle, activeChatbotGroupId, availableChatbotGroups }) => {
  const location = useLocation()
  const navigate = useNavigate()
  const params = useParams()

  const [ logoutConfirmIsOpened, setLogoutConfirmIsOpened ] = useState(false)
  const [ changeCbGroupProcessing, setChangeCbGroupProcessing ] = useState(false)
  const [ selectedCbGroupToProcess, setSelectedCbGroupToProcess ] = useState(undefined)

  const handleOpenLogoutConfirm = () => {
    setLogoutConfirmIsOpened(true)
  }

  const handleConfirm = () => {
    UserService.logout()
    setLogoutConfirmIsOpened(false)

    navigate('/auth')
  }

  const handleCancel = () => {
    setLogoutConfirmIsOpened(false)
  }

  const handleCbGroupChange = async (cbGroupId) => {
    if (activeChatbotGroupId === cbGroupId) return

    try {
      setChangeCbGroupProcessing(true)
      setSelectedCbGroupToProcess(cbGroupId)
      await SettingsService.setActiveChatbotGroup(cbGroupId)

      let route = location.pathname

      for (const paramKey in params) {
        route = route.replace(`/${params[paramKey]}`, '')
      }

      navigate(route)
      window.location.reload()
    } catch (error) {
      NotificationsService.error('Не удалось сменить группу')
      throw error
    } finally {
      setChangeCbGroupProcessing(false)
    }
  }

  //
  // Render
  //

  function renderBalance () {
    const { smsc_balance: balance = 0.0 } = userData

    let color = 'green'

    const text_style = {}

    let className = ''

    if (+balance <= 500) {
      color = 'red'
      text_style.color = '#db2828'
      className = 'tada transition'
    }

    return (
      <Menu.Item className="site-header__icon-on-mobile" href="https://smsc.ru/pay" target="_blank">
        <Icon size="large" name="envelope outline" className={className} color={color} />
        <span style={text_style}>{balance} руб.</span>
      </Menu.Item>
    )
  }

  const renderCbGroupSelect = () => {
    const countryOptions = availableChatbotGroups.map((acg) => ({ key: acg.id, value: acg.id, text: acg.name }))
    const isOnlyGroup = countryOptions.length < 2

    return (
      <Menu.Item className={cn('site-header__cb-select', { 'site-header__cb-select--only': isOnlyGroup })}>
        <Dropdown
          onChange={(_e, { value }) => handleCbGroupChange(value)}
          placeholder={userData.title || 'BEINTOUCH'}
          selectOnBlur={false}
          search={!isOnlyGroup}
          selection
          selectOnNavigation={false}
          disabled={changeCbGroupProcessing}
          loading={changeCbGroupProcessing}
          value={selectedCbGroupToProcess || activeChatbotGroupId}
          options={countryOptions}
        />
      </Menu.Item>
    )
  }

  return (
    <Menu attached className="site-header">
      <Menu.Menu>
        <Menu.Item as="a" id="burger inverted" color="green" onClick={sliderToggle}>
          <Icon size="large" color="green" name="content" className="site-header__toggle-sidebar-icon" />
        </Menu.Item>

        {renderCbGroupSelect()}
      </Menu.Menu>

      <Menu.Menu position="right">
        <Menu.Item position="right" as="span">
          <span className="site-header__username">{userData.phone || ''}</span>
        </Menu.Item>

        {renderBalance()}

        <Menu.Item className="site-header__icon-on-mobile" as={Link} to="/profile" title="Профиль">
          <Icon size="large" name="setting" />
          <span>Профиль</span>
        </Menu.Item>

        <Menu.Item as="a" className="site-header__icon-on-mobile" onClick={handleOpenLogoutConfirm} title="Выход">
          <Icon size="large" name="sign out" />
          <span>Выход</span>
        </Menu.Item>

        <Confirm
          size="tiny"
          header="Подтверждение выхода"
          content="Вы действительно хотите выйти?"
          isOpened={logoutConfirmIsOpened}
          onConfirm={handleConfirm}
          onCancel={handleCancel}
        />
      </Menu.Menu>
    </Menu>
  )
}

export default SiteHeader
