import React from 'react'
import { Icon, Sidebar, Container, Item, Label, Accordion } from 'semantic-ui-react'
import { NavLink } from 'react-router-dom'
import MobileDetect from 'mobile-detect'

import './MainMenu.scss'

const NAVIGATION_CONFIG = [
  {
    key: 'orders',
    icon: 'book',
    title: 'Заказы',
    getPath: () => '/orders'
  },
  {
    key: 'waiters',
    icon: 'address card',
    title: 'Официанты',
    getPath: () => '/waiters'
  },
  {
    key: 'reviews',
    icon: 'bookmark',
    title: 'Отзывы',
    getPath: () => '/reviews'
  },
  {
    key: 'chat',
    icon: 'chat',
    title: 'Чат',
    getPath: () => '/chats'
  },
  {
    key: 'bulks',
    icon: 'send',
    title: 'Рассылки',
    getPath: () => '/bulks'
  },
  {
    key: 'promotions',
    icon: 'book',
    title: 'Акции',
    getPath: () => '/promotions',
    group: true,
    groups: [
      {
        key: 'promotions_base',
        title: 'Базовые',
        getPath: () => '/promotions/base'
      },
      {
        key: 'promotions_trigger',
        title: 'Триггерные',
        getPath: () => '/promotions/trigger'
      }
    ]
  },
  {
    key: 'polls',
    icon: 'star',
    title: 'Опросы',
    getPath: () => '/polls'
  },
  {
    key: 'marketing',
    title: 'Маркетинг',
    getPath: () => '/marketing',
    group: true,
    groups: [
      {
        key: 'links',
        title: 'Ссылки',
        getPath: () => '/marketing/links'
      }
    ]
  },
  {
    key: 'subscribers',
    icon: 'user',
    title: 'База гостей',
    getPath: () => '/subscribers'
  },
  {
    key: 'settings',
    icon: 'settings',
    title: 'Настройки',
    getPath: () => '/settings'
  }
]

class MainMenu extends React.PureComponent {
  static defaultProps = {
    visible: true,
    onHide: () => {}
  }

  state = {
    modalIsOpened: false
  }

  constructor (props) {
    super(props)

    const md = new MobileDetect(window.navigator.userAgent)

    this.state = {
      isMobile: md.mobile(),
      activeGroup: null,
      activePartner: null,
      path: false
    }
  }

  handleModalToggle () {
    this.setState({ modalIsOpened: !this.state.modalIsOpened })
  }

  setActiveGroup (activeGroup) {
    this.setState({ activeGroup })
  }

  setActivePartner (activePartner) {
    this.setState({ activePartner })
  }

  handleTitleClick = (e, itemProps) => {
    if (this.state.activeGroup === itemProps.index) {
      this.setActiveGroup(null)
    } else {
      this.setActiveGroup(itemProps.index)
    }
  }

  renderTopMenuItems () {
    const { activeGroup, isMobile } = this.state
    const { onHide } = this.props
    const groupProps = {}

    if (isMobile) groupProps.onNavigate = onHide

    const allPanels = NAVIGATION_CONFIG.map(item => {
      if (!item.groups || !item.group) return null

      const isActive = activeGroup === item.key
      const { onNavigate } = this.props
      const content = (
        <Accordion.Content className='huge menu'>
          {item.groups.map(button => (
            <NavLink
              key={button.key}
              className={({ isActive }) => `item main-menu__menu-item ${isActive ? 'main-menu__menu-item_selected' : ''}`}
              to={button.getPath()}
              onClick={onNavigate}
            >
              <Icon name={button.icon} />

              {button.title}
            </NavLink>
          ))}
        </Accordion.Content>
      )

      return {
        key: item.key,
        title: (
          <Accordion.Title
            active={isActive}
            index={item.key}
            onClick={this.handleClick}
          >
            {item.title}
            <Icon name='dropdown' />
          </Accordion.Title>
        ),
        content: {
          key: `content-${item.key}`,
          active: isActive,
          content
        }
      }
    }).filter(Boolean)

    return NAVIGATION_CONFIG.map(item => {
      const { target } = item
      const panel = allPanels.find(p => p.key === item.key)

      let badge = null

      // Chat badge
      if (item.key === 'chat' && !!this.props.unreadCounter) {
        badge = <Label floating size='tiny' circular color="green" active={true} className='left-bandle'>{this.props.unreadCounter}</Label>
      }

      // New subs badge
      if (item.key === 'subscribers' && !!this.props.unseenGuests) {
        badge = <Label floating size='tiny' circular color="green" active={true} className='left-bandle-subscriber'>{this.props.unseenGuests}</Label>
      }

      return item.group && panel ? (
        <div key={item.key}>
          <Accordion
            exclusive={false}
            inverted={true}
            onTitleClick={this.handleTitleClick}
            className='main-menu__menu'
            panels={[ panel ]}
          />
        </div>
      ) : (
        <NavLink
          key={item.key}
          to={item.getPath()}
          className={({ isActive }) => `item main-menu__menu-item ${isActive ? 'main-menu__menu-item_selected' : ''}`}
          target={target}
        >
          <Icon name={item.icon} />

          {item.title}
          {badge}
        </NavLink>
      )
    })
  }

  render () {
    const { isMobile } = this.state
    const { visible, onHide } = this.props
    const sidebarProps = { animation: 'push' }

    if (isMobile) {
      sidebarProps.animation = 'overlay'
      sidebarProps.onHide = onHide
    }

    return (
      <Sidebar
        className='main-menu vertical inverted menu'
        visible={visible}
        {...sidebarProps}
      >
        <Item>
          <h3>Разделы</h3>
        </Item>
        <hr/>
        <Container id='main-menu__menu-wrapper' className='main-menu__menu-wrapper' fluid>
          {this.renderTopMenuItems()}
        </Container>
      </Sidebar>
    )
  }
}

export default MainMenu
