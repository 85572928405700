import React, { Component } from 'react'
import Select from 'react-select'
import cloneDeep from 'lodash/cloneDeep'

const selectAllOption = {
  value: 'selectAll',
  key: 'selectAll',
  label: 'Все'
}
export const customStyle = {
  valueContainer: provided => ({
    ...provided,
    cursor: 'text'
  }),
  control: (provided, { menuIsOpen }) => {
    return {
      ...provided,
      borderColor: menuIsOpen ? 'rgb(150, 200, 218) !important' : 'rgba(34, 36, 38, 0.15)',
      boxShadow: 'none',
      '&:hover': {
        borderColor: 'rgba(34, 36, 38, 0.35)'
      },
      '& > div:first-of-type': {
        maxHeight: '97px',
        overflowY: 'auto'
      }
    }
  },
  placeholder: provided => ({
    ...provided,
    color: 'rgba(191, 191, 191, 0.87)'
  }),
  menu: (provided) => {
    const resState = {
      ...provided,
      zIndex: 10000
    }

    return resState
  },
  option: (provided, { data }) => {
    const resState = {
      ...provided,
      cursor: 'pointer',
      zIndex: 10000,
      color: 'rgba(0, 0, 0, 0.95)',
      background: 'none',
      '&:hover,&:active': {
        background: 'rgba(0, 0, 0, 0.05)'
      }
    }

    if (data.value === 'selectAll') {
      resState.fontWeight = 'bold'
    }

    if (data.value === 'isNull') {
      resState.fontWeight = 'bold'
    }

    return resState
  },
  multiValue: provided => ({
    ...provided,
    border: '1px solid #e0e0e0'
  }),
  multiValueRemove: provided => ({
    ...provided,
    cursor: 'pointer'
  }),
  dropdownIndicator: provided => ({
    ...provided,
    cursor: 'pointer'
  })
}

export default class SelectAllDropdown extends Component {
  static defaultProps = {
    onChange: () => {},
    isEdit: false
  }

  state = {
    selectedItems: [],
    selectedAll: true
  }

  componentDidMount () {
    if (this.props.isEdit) {
      this.setState({
        selectedAll: false
      })
    } else {
      this.props.isSelectedAll(this.state.selectedAll)
    }
  }

  componentDidUpdate () {
    if (!this.props.value.length && this.state.selectedAll === true) this.setState({ selectedAll: false, selectedItems: [] })
    if (this.props.value[0] === 'allValueSelected' && this.state.selectedAll === false) this.setState({ selectedAll: true, selectedItems: [ selectAllOption ] })
  }

  static getDerivedStateFromProps ({ value = [], options }, { selectedAll }) {
    let selectedItems = []

    if (selectedAll) {
      selectedItems = [ selectAllOption ]
    } else {
      selectedItems = value
        .map(item => options.find(option => (option.value === item)))
        .filter(item => item)
    }

    return { selectedItems }
  }

  handleDropdownChange = async (value, { option = {} }) => {
    let selectedAll = false
    let resValue = value || []

    if (option.value === selectAllOption.value) {
      const { options } = this.props

      resValue = options.filter(item => item.value !== 'isNull').map(item => item.value)
      selectedAll = true
    } else {
      resValue = resValue.map(item => item.value)
    }

    this.setState({ selectedAll }, () => this.props.onChange(resValue, selectedAll))

    this.props.isSelectedAll(selectedAll)
  }

  render () {
    const { placeholder, options, disabled, loading } = this.props
    const { selectedItems, selectedAll } = this.state
    let resOptions = cloneDeep(options)

    if (!options.length) {
      resOptions = []
    } else {
      if (selectedAll) {
        resOptions = []
        resOptions.unshift(selectAllOption)
      } else {
        const isNull = options[0]

        if (isNull.value === 'isNull') {
          resOptions.splice(0, 1)
          resOptions.unshift(selectAllOption)
          resOptions.unshift(isNull)
        } else {
          resOptions.unshift(selectAllOption)
        }
      }
    }

    return (
      <Select
        className='select-all-dropdown'
        styles={customStyle}
        closeMenuOnSelect={false}
        isClearable={false}
        isMulti
        isSearchable
        noOptionsMessage={() => 'Ничего не найдено'}
        components={{
          IndicatorSeparator: () => null
        }}
        options={resOptions}
        value={selectedItems}
        placeholder={placeholder}
        onChange={this.handleDropdownChange}
        isDisabled={disabled}
        isLoading={loading}
      />
    )
  }
}
