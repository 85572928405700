import React, { PureComponent } from 'react'

import './Spoiler.scss'

export default class Spoiler extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      collapsedComment: false
    }

    this.imageRef = React.createRef()
  }

  static defaultProps = {
    text: null,
    onChange: () => {},
    fluid: false,
    disabled: false
  };

  handleCollapsedComment () {
    const collapsed = this.state.collapsedComment
    this.setState({ collapsedComment: !collapsed })
  }

  render () {
    const { text } = this.props

    if (!text || text.length < 80) return text

    const collapsed = this.state.collapsedComment
    const expandedComment = text.substr(0, 80)

    return (
      <div className="spoiler" onClick={this.handleCollapsedComment.bind(this)}>
        {collapsed ? text : expandedComment + '...'}{' '}
        <span onClick={this.handleCollapsedComment.bind(this)}>{collapsed ? 'свернуть' : 'подробнее'}</span>
      </div>
    )
  }
}
