import React, { Suspense } from 'react'
import { Provider } from 'react-redux'
import { createRoot } from 'react-dom/client'

import { RouteConfig } from './routes'
import { store } from './store'
import { initSentry } from './plugins/sentry'

/* Styles of the Semantic UI lib */
import 'semantic-ui-css/semantic.min.css'

initSentry()

const rootElement = document.getElementById('root')
const root = createRoot(rootElement!)

root.render(
  <Provider store={store}>
    <Suspense fallback="Загрузка...">
      <RouteConfig />
    </Suspense>
  </Provider>
)
