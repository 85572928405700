import React, { Component } from 'react'

import Alert from 'react-s-alert'
import CustomAlertTemplate from './CustomAlertTemplate'

import 'react-s-alert/dist/s-alert-default.css'
import 'react-s-alert/dist/s-alert-css-effects/stackslide.css'
import './AlertWrapper.scss'

export default class AlertWrapper extends Component {
  render () {
    return (
      <div {...this.props}>
        {this.props.children}

        <Alert
          contentTemplate={CustomAlertTemplate}
          beep={false}
          stack={{ limit: 5 }}
          html={true}
          position='top'
          effect='stackslide'
          timeout={5000}
        />
      </div>
    )
  }
}
