import { differenceInDays, format, isValid } from 'date-fns'

import BaseService from './BaseService'

export default class CommonService extends BaseService {
  static scrollToField (elementId: any) {
    const element = document.getElementById(elementId)

    if (!element) return null

    const fieldElement = element.id === elementId ? element : element.closest('.field')

    if (fieldElement) fieldElement.scrollIntoView({ block: 'center', behavior: 'smooth' })
  }

  static focusToField (elementId: any) {
    const element = document.getElementById(elementId)

    if (!element) return
    element.focus()
  }

  static formatDate (date: string | undefined | Date | null, dateFormat = 'dd.MM.yyyy'): string {
    if (!date) return ''

    const momentDate = new Date(date)

    if (!isValid(momentDate)) return ''

    return format(momentDate, dateFormat)
  }

  static formatYear (date: any, dateFormat = 'yyyy') {
    if (!date) return ''

    const momentDate = new Date(date)

    if (!isValid(momentDate)) return ''

    return format(momentDate, dateFormat)
  }

  static formatTime (date: any, dateFormat = 'HH:mm') {
    if (!date) return ''

    const momentDate = new Date(date)

    if (!isValid(momentDate)) return ''

    return format(momentDate, dateFormat)
  }

  static isToday (date: string | Date): boolean {
    return differenceInDays(new Date(date), new Date()) === 0
  }

  static formatNumber (number = 0, fractionDigits = 2) {
    return (+(+number).toFixed(fractionDigits)).toLocaleString('ru-RU')
  }

  static formatCurrency (number: any, fractionDigits = 1) {
    return `${this.formatNumber(number, fractionDigits)} руб.`
  }

  static formatPhone (number: string | undefined): string {
    if (!number) return ''

    return number.replace(/(\d{1})(\d{3})(\d{3})(\d+)/, '+$1 ($2) $3-$4')
  }

  static validatePositive (number: any) {
    return !isNaN(parseFloat(number)) && !(Number(number) < 0.0)
  }

  static validatePercent (number: any) {
    return !isNaN(parseFloat(number)) && !(Number(number) > 100.0) && !(Number(number) < 0.0)
  }
}
