import React, { PureComponent } from 'react'

import './InfiniteScroll.scss'
import { AlertWrapper, Loading } from '..'
import { Fade } from '../animations'

function DefaultLoader () {
  return (
    <AlertWrapper>
      <Loading withoutText size="small" />
    </AlertWrapper>
  )
}

type Props = {
  children: React.ReactNode[],
  hasMore: boolean,
  id?: string,
  initialLoad?: boolean,
  isReverse?: boolean,
  loader?: typeof DefaultLoader,
  loadMore: () => Promise<null | void> | undefined,
  ref?: any,
  pageStart?: number,
  threshold: number,
  useCapture?: boolean,
  useWindow: boolean,
  isLoading: boolean,
  initialLoading?: boolean,
  onWheel?: () => void,
  onTouchMove?: () => void,
}

type State = {
  isIssueBrowser: boolean | null,
  isIssueBrowserChecked: boolean | null
}

class InfiniteScroll extends PureComponent<Props, State> {
  private loaderRef: React.RefObject<HTMLDivElement>
  private pageLoaded: number | null
  private scrollComponent: HTMLElement | null

  static defaultProps = {
    hasMore: false,
    initialLoad: true,
    pageStart: 0,
    ref: null,
    threshold: 250,
    useWindow: true,
    isReverse: false,
    useCapture: false,
    loader: DefaultLoader,
    isLoading: false,
    initialLoading: false,
    onWheel: () => {},
    onTouchMove: () => {}
  }

  constructor (props: Props) {
    super(props)

    this.loaderRef = React.createRef()

    this.scrollListener = this.scrollListener.bind(this)

    this.state = {
      isIssueBrowser: null,
      isIssueBrowserChecked: null
    }

    this.pageLoaded = null
    this.scrollComponent = null
  }

  componentDidMount () {
    if (this.props.pageStart) {
      this.pageLoaded = this.props.pageStart
    }
    this.attachScrollListener()
  }

  componentDidUpdate () {
    const { isIssueBrowserChecked, isIssueBrowser } = this.state

    if (!isIssueBrowserChecked && isIssueBrowser == null && this.scrollComponent && this.props.children.length > 0 && this.scrollComponent.scrollTop !== 0) {
      this.setState({ isIssueBrowser: true, isIssueBrowserChecked: true })
    } else if (!isIssueBrowserChecked && this.props.children.length > 0) {
      this.setState({ isIssueBrowser: false, isIssueBrowserChecked: true })
    }

    this.attachScrollListener()
  }

  componentWillUnmount () {
    this.detachScrollListener()
  }

  attachScrollListener () {
    if (!this.props.hasMore) return

    const scrollEl = this.props.useWindow ? window : this.scrollComponent

    scrollEl?.addEventListener(
      'scroll',
      this.scrollListener,
      this.props.useCapture
    )
    scrollEl?.addEventListener(
      'resize',
      this.scrollListener,
      this.props.useCapture
    )

    if (this.props.initialLoad) {
      this.scrollListener()
    }
  }

  detachScrollListener () {
    const scrollEl = this.props.useWindow ? window : this.scrollComponent

    scrollEl?.removeEventListener(
      'scroll',
      this.scrollListener,
      this.props.useCapture
    )
    scrollEl?.removeEventListener(
      'resize',
      this.scrollListener,
      this.props.useCapture
    )
  }

  scrollListener () {
    const el = this.scrollComponent
    let offset: number | null = null

    if (this.props.isReverse && el) {
      // Actually we have a bunch of browsers that compute `el.scrollTop` incorrectly
      // while `flex-direction: column-reverse;` is enabled to list
      offset = this.state.isIssueBrowser ? Math.abs(el.scrollTop) : el.scrollHeight - Math.abs(el.scrollTop) - el.clientHeight
    } else if (el) {
      offset = el.scrollHeight - el.scrollTop - el.clientHeight
    }

    if (offset !== null && offset < Number(this.props.threshold)) {
      this.detachScrollListener()
      // Call loadMore after detachScrollListener to allow for non-async loadMore functions
      if (typeof this.props.loadMore === 'function') {
        this.props.loadMore()
      }
    }
  }

  //
  // Computed
  //

  getLoaderHeight = () => {
    return this.loaderRef.current ? this.loaderRef.current.offsetHeight : 0
  }

  getListStyles = () => {
    const { isLoading, isReverse } = this.props
    const loaderHeight = this.getLoaderHeight()

    let transform = isLoading ? `translateY(${-loaderHeight}px)` : 'translateY(0)'

    if (isReverse) {
      transform = isLoading ? `translateY(${loaderHeight}px)` : `translateY(${0}px)`
    }

    return {
      transform
    }
  }

  getLoaderStyles = () => {
    const { isReverse, isLoading } = this.props
    const loaderHeight = this.getLoaderHeight()

    let transform = isLoading ? 'translateY(0)' : `translateY(${loaderHeight}px)`
    let bottom: number | undefined = 0
    let top: number | undefined
    const opacity = isLoading ? 1 : 0

    if (isReverse) {
      transform = isLoading ? 'translateY(0)' : `translateY(${-loaderHeight}px)`
      bottom = undefined
      top = 0
    }

    return {
      transform,
      bottom,
      top,
      opacity
    }
  }

  //
  // Render
  //

  render () {
    const {
      children,
      hasMore,
      initialLoad,
      isReverse,
      initialLoading,
      loader: Loader = DefaultLoader,
      loadMore,
      pageStart,
      threshold,
      useCapture,
      useWindow,
      isLoading,
      ...props
    } = this.props

    const { ref } = this.props

    props.ref = (node: HTMLDivElement) => {
      this.scrollComponent = node
      if (ref) {
        ref(node)
      }
    }

    const loaderStyles = this.getLoaderStyles()
    const listStyles = this.getListStyles()

    const loaderNode = hasMore && (
      <div
        className="infinite-scroller__loader"
        style={loaderStyles}
        ref={this.loaderRef}
      >
        <Loader />
      </div>
    )

    const renderInitialLoading = (
      <div className={`infinite-scroller__initial-loader ${initialLoading ? '' : 'infinite-scroller__initial-loader--hide'}`}>
        <Fade>
          <Loading size="small" />
        </Fade>
      </div>
    )

    const reversedClass = isReverse ? 'infinite-scroller_reversed' : ''

    return (
      <div className='infinite-scroller'>
        {renderInitialLoading}
        {isReverse && loaderNode}
        <div
          {...props}
          className={`infinite-scroller__list ${reversedClass}`}
          style={listStyles}
        >
          {[ children ]}
        </div>
        {!isReverse && loaderNode}
      </div>
    )
  }
}

export default InfiniteScroll
