import {
  map, sortBy,
  keys as _keys, zipObject
} from 'lodash'

export default (obj, comparator) => {
  const keys = sortBy(_keys(obj), key => (comparator ? comparator(obj[key], key) : key)).reverse()

  return zipObject(keys, map(keys, key => obj[key]))
}
