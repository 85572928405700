import React, { Component } from 'react'
import { Input } from 'semantic-ui-react'
import debounce from 'lodash/debounce'

export default class DebouncedInput extends Component {
  static defaultProps = {
    wait: 600,
    onChange: () => {}
  }

  constructor (props) {
    super(props)

    const { value, wait, onChange } = props

    this.state = { value }
    this.onChangeDebounced = debounce(onChange, wait)
  }

  componentWillUnmount () {
    this.onChangeDebounced.cancel()
  }

  handleInputChange = (event, result) => {
    const { value } = result

    this.setState({ value })
    this.onChangeDebounced(event, result)
  }

  render () {
    const { name, placeholder, fluid } = this.props
    const { value } = this.state

    return (
      <Input
        name={name}
        value={value}
        placeholder={placeholder}
        fluid={fluid}
        onChange={this.handleInputChange}
      />
    )
  }
}
