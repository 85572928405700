const openAnswer = {
  value: 'open_answer',
  text: 'Открытый ответ'
}
const oneOption = {
  value: 'one_option',
  text: 'Один вариант из списка'
}
const severalOptions = {
  value: 'several_options',
  text: 'Несколько вариантов из списка'
}
const yesOrNo = {
  value: 'yes_or_no',
  text: 'Ответ ДА/НЕТ'
}
const fiveScale = {
  value: 'five_scale',
  text: 'Оценка по 5-ти бальной шкале'
}

export default {
  openAnswer,
  oneOption,
  severalOptions,
  yesOrNo,
  fiveScale
}
