import BaseService from './BaseService'
import { API_URL, httpClient } from '@/api/httpClient'
import UrlService from './UrlService'

export default class ReviewService extends BaseService {
  static async restaurants (query = null) {
    let url = `${API_URL}/protected/reviews/restaurants`

    if (query) url = `${API_URL}/protected/reviews/restaurants?${query}`

    const { data } = await httpClient.get(url)

    return data
  }

  static getReviewsListPayload (payload, payloadQ) {
    const filters = new URLSearchParams()
    filters.set('page', payload.page)
    filters.set('per_page', payload.per_page)

    const q = UrlService.getFiltersQuery(payloadQ)

    return `${filters}&${q}`
  }

  static async getReviewsList (payload) {
    const data = await httpClient.get(`/protected/reviews?${payload}`)

    return data
  }
}
