import React, { Component } from 'react'

export default class ConditionalHidden extends Component {
  render () {
    const style = {}

    if (this.props.hidden) {
      if (this.props.withoutRender) {
        return null
      }

      style.display = 'none'
    }

    // Allows us to pass type or component into "as"
    return React.createElement(this.props.as || 'div', { style }, this.props.children)
  }
}
