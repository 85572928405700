declare global {
  interface Window {
    zE: any
  }
}

export function zendeskHide () {
  window.zE('messenger:set', 'cookies', false)
}

export function zendeskShow () {
  window.zE('messenger:set', 'cookies', true)
}
