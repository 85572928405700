import { getPercentage } from '../mathUtils'

export const mapDtoToLinkPiece = (dto) => ({
  date: dto.created_at,
  linkMovement: dto.counters ? (!!dto.counters.clicked || false) : false,
  joinUs: dto.counters ? (!!dto.counters.join_us_opened || false) : false,
  channelBtnPressed: dto.counters ? (!!dto.counters.join_us_clicked_button || false) : false,
  registrationStart: dto.counters ? (!!dto.counters.guest_messenger_opened || false) : false,
  numberEntered: dto.counters ? (!!dto.counters.guest_messenger_phone_sended || false) : false,
  smsEntered: dto.counters ? (!!dto.counters.guest_messenger_phone_confirmed || false) : false,
  formMovement: dto.counters ? (!!dto.counters.registration_page_opened || false) : false,
  registration: dto.counters ? (!!dto.counters.registration_page_done || false) : false,
  phone: dto.phone || ''
})

export const mapDtoToLinkCounters = (dto) => {
  const linkMovements = dto ? (dto.clicked || 0) : 0
  const joinUs = dto ? (dto.join_us_opened || 0) : 0
  const channelBtnPressed = dto ? (dto.join_us_clicked_button || 0) : 0
  const registrationStart = dto ? (dto.guest_messenger_opened || 0) : 0
  const numberEntered = dto ? (dto.guest_messenger_phone_sended || 0) : 0
  const smsEntered = dto ? (dto.guest_messenger_phone_confirmed || 0) : 0
  const formMovements = dto ? (dto.registration_page_opened || 0) : 0
  const registrations = dto ? (dto.registration_page_done || 0) : 0

  return {
    linkMovements,
    joinUs,
    channelBtnPressed,
    registrationStart,
    numberEntered,
    smsEntered,
    formMovements,
    registrations,
    linkMovementsPercent: linkMovements === 0 ? 0 : 100,
    joinUsPercent: getPercentage(joinUs, linkMovements),
    channelBtnPressedPercent: getPercentage(channelBtnPressed, linkMovements),
    registrationStartPercent: getPercentage(registrationStart, linkMovements),
    numberEnteredPercent: getPercentage(numberEntered, linkMovements),
    smsEnteredPercent: getPercentage(smsEntered, linkMovements),
    formMovementsPercent: getPercentage(formMovements, linkMovements),
    registrationsPercent: getPercentage(registrations, linkMovements)
  }
}

export const mapDtoToLink = (dto) => {
  return ({
    id: dto.id,
    name: dto.name,
    comment: dto.comment,
    link: dto.full_link,
    date: dto.created_at,
    source: dto.source,
    counters: dto.counters && mapDtoToLinkCounters(dto.counters)
  })
}
