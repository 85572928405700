import BaseService from './BaseService'
import { httpClient } from '@/api/httpClient'
import UrlService from './UrlService'

export default class BonusRulesService extends BaseService {
  static async create ({ text }) {
    return httpClient.post('/protected/bonus_system_rules', { text })
  }

  static async update (id, { text }) {
    return httpClient.put(`/protected/bonus_system_rules/${id}`, { text })
  }

  static async getBonusRules (page, itemsPerPage, payloadQ) {
    const q = UrlService.getFiltersQuery(payloadQ)

    const data = await httpClient.get(`/protected/bonus_system_rules?page=${page}&per_page=${itemsPerPage}&${q}`)

    return data
  }
}
