import React from 'react'
import { Modal, ModalActionsProps, ModalContentProps, ModalDimmerProps, ModalHeaderProps, ModalProps, SemanticShorthandItem } from 'semantic-ui-react'

type Props = {
  /* An element type to render as (string or function). */
  as?: any,

  /* Shorthand for Modal.Actions. Typically an array of button shorthand. */
  actions?: SemanticShorthandItem<ModalActionsProps>,

  /* A modal can reduce its complexity */
  basic?: boolean,

  /* A modal can be vertically centered in the viewport */
  centered?: boolean,

  /* Primary content. */
  children?: React.ReactNode,

  /* Additional classes. */
  className?: string,

  /* Shorthand for the close icon. Closes the modal on click. */
  closeIcon?: any,

  /* Whether or not the Modal should close when the dimmer is clicked. */
  closeOnDimmerClick?: boolean,

  /* Whether or not the Modal should close when the document is clicked. */
  closeOnDocumentClick?: boolean,

  /* Simple text content for the Modal. */
  content?: SemanticShorthandItem<ModalContentProps>,

  /* Initial value of open. */
  defaultOpen?: boolean,

  /* A Modal can appear in a dimmer. */
  dimmer?: true | 'blurring' | 'inverted' | SemanticShorthandItem<ModalDimmerProps>,

  /* Event pool namespace that is used to handle component events */
  eventPool?: string,

  /* Modal displayed above the content in bold. */
  header?: SemanticShorthandItem<ModalHeaderProps>,

  /* The node where the modal should mount. Defaults to document.body. */
  mountNode?: any,

  /*
   * Action onClick handler when using shorthand actions.
   *
   * @param {SyntheticEvent} event - React's original SyntheticEvent.
   * @param {object} data - All props.
   */
  onActionClick?: (event: React.MouseEvent<HTMLElement>, data: ModalProps) => void,

  /*
   * Called when a close event happens.
   *
   * @param {SyntheticEvent} event - React's original SyntheticEvent.
   * @param {object} data - All props.
   */
  onClose?: (event?: React.MouseEvent<HTMLElement>, data?: ModalProps) => void,

  /*
   * Called when the portal is mounted on the DOM.
   *
   * @param {null}
   * @param {object} data - All props.
   */
  onMount?: (nothing: null, data: ModalProps) => void,

  /*
   * Called when an open event happens.
   *
   * @param {SyntheticEvent} event - React's original SyntheticEvent.
   * @param {object} data - All props.
   */
  onOpen?: (event: React.MouseEvent<HTMLElement>, data: ModalProps) => void,

  /*
   * Called when the portal is unmounted from the DOM.
   *
   * @param {null}
   * @param {object} data - All props.
   */
  onUnmount?: (nothing: null, data: ModalProps) => void,

  /* Controls whether or not the Modal is displayed. */
  open?: boolean,

  /* A modal can vary in size */
  size?: 'mini' | 'tiny' | 'small' | 'large' | 'fullscreen',

  /* Custom styles. */
  style?: React.CSSProperties,

  /* Element to be rendered in-place where the portal is defined. */
  trigger?: React.ReactNode,

  /* Should ask to close by Dimmer or Esc button. */
  shouldConfirmClose?: boolean,

  /* Before close message by Dimmer or Esc button if `shouldConfirmClose` is `true`. */
  confirmCloseMessage?: string,

  entered?: true,
  closeOnEscape?: boolean,
}

const AppModal: React.FC<Props> = (props) => {
  const {
    as,
    actions,
    basic,
    centered,
    children,
    className,
    closeIcon,
    closeOnDimmerClick,
    closeOnDocumentClick,
    content,
    defaultOpen,
    dimmer,
    eventPool,
    header,
    mountNode,
    onActionClick,
    onClose,
    onMount,
    onOpen,
    onUnmount,
    open,
    size,
    style,
    trigger,
    shouldConfirmClose,
    confirmCloseMessage
  } = props
  //
  // Methods
  //

  const handleClose = () => {
    if (!shouldConfirmClose && onClose) {
      onClose()
      return
    }

    if (window.confirm(confirmCloseMessage) && onClose) {
      onClose()
    }
  }

  //
  // Render
  //

  return (
    <Modal
      as={as}
      actions={actions}
      basic={basic}
      centered={centered}
      className={className}
      closeIcon={closeIcon}
      closeOnDimmerClick={closeOnDimmerClick}
      closeOnDocumentClick={closeOnDocumentClick}
      content={content}
      defaultOpen={defaultOpen}
      dimmer={dimmer}
      eventPool={eventPool}
      header={header}
      mountNode={mountNode}
      onActionClick={onActionClick}
      onClose={handleClose}
      onMount={onMount}
      onOpen={onOpen}
      onUnmount={onUnmount}
      open={open}
      size={size}
      style={style}
      trigger={trigger}
    >
      {children}
    </Modal>
  )
}

AppModal.defaultProps = {
  centered: true,
  dimmer: true,
  closeOnDimmerClick: true,
  closeOnEscape: true,
  closeOnDocumentClick: false,
  eventPool: 'Modal',
  shouldConfirmClose: false,
  onClose: () => {},
  confirmCloseMessage: 'На странице есть несохраненные изменения, которые будут утеряны при закрытии. Закрыть?'
}

export default AppModal
