const planned = {
  value: 'planned',
  name: 'В плане',
  colorName: 'blue'
}
const inProcess = {
  value: 'in_process',
  name: 'В процессе',
  colorName: 'yellow'
}
const completed = {
  value: 'completed',
  name: 'Завершен',
  colorName: 'green'
}

export default {
  planned,
  inProcess,
  completed
}
