import { AxiosResponse } from 'axios'

import Config from 'Config'
import BaseService from './BaseService'
import { httpClient } from '@/api/httpClient'
import { Sentry } from '@/plugins/sentry'
import { User } from '@/models/entity'
import { UploadImage } from '@/models/entity/uploadImage/UploadImage'
import { zendeskHide, zendeskShow } from './ZendeskService'

export default class UserService extends BaseService {
  static get isAuthenticated () {
    return !!localStorage.getItem('@usr_t')
  }

  static get authToken (): string | null {
    return localStorage.getItem('@usr_t')
  }

  static async login ({ session_id, code }: { session_id: string; code: string }): Promise<void> {
    type Payload = {
      session_id: string;
      code: string;
    };
    type Response = {
      token: string;
    };
    const { data } = await httpClient.post<Payload, AxiosResponse<Response>>('/public/login', { session_id, code })

    if (data?.token) {
      localStorage.setItem('@usr_t', data.token)

      Config.showZendesk && window.zE && zendeskShow()
    }
  }

  static async auth (token: string, name: string) {
    type Payload = {
      name: string;
      token: string;
    };
    type Response = {
      token: string;
    };
    const { data } = await httpClient.post<Payload, AxiosResponse<Response>>('/public/connect_by_token', {
      token,
      name
    })

    data?.token && localStorage.setItem('@usr_t', data.token)
  }

  static async startSmsSession ({ phone, kind }: { phone: string; kind: string }) {
    type Payload = {
      phone: string;
      kind: string;
    };
    type Response = {
      session_id: string;
    };
    const { data } = await httpClient.post<Payload, AxiosResponse<Response>>('/public/sms_confirmation/start_session', {
      phone,
      kind
    })

    return { session_id: data.session_id }
  }

  static logout () {
    localStorage.removeItem('@usr_t')
    Sentry.configureScope((scope) => scope.setUser(null))
    Config.showZendesk && zendeskHide()
  }

  static async getMe () {
    const { data } = await httpClient.get<null, AxiosResponse<User>>('/protected/users/me')

    return data
  }

  static async uploadImage (image: string) {
    type Payload = {
      file: string;
    };
    const { data } = await httpClient.post<Payload, AxiosResponse<UploadImage>>('/protected/users/upload_image', { file: image })

    return data
  }

  static async uploadCsv (file: any) {
    return httpClient.post('/protected/users/upload_csv', { file: file })
  }
}
