function purifyObject (obj) {
  if (obj == null) return {}

  Object.keys(obj).forEach(key => {
    const value = obj[key]
    const hasProperties = value && Object.keys(value).length > 0

    if (value === null) {
      delete obj[key]
    } else if ((typeof value !== 'string') && hasProperties) {
      purifyObject(value)
    }
  })

  return obj
}

export default purifyObject
