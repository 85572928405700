import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic'

type Constant = {
  selfDelivery: {
    color: SemanticCOLORS,
    text: string
  },
  delivery: {
    color: SemanticCOLORS,
    text: string
  },
  banquet: {
    text: string,
    color: SemanticCOLORS
  },
  hall: {
    text: string,
    color: SemanticCOLORS
  }
}

const orderTypes: Constant = {
  selfDelivery: {
    color: 'olive',
    text: 'Самовывоз'
  },
  delivery: {
    color: 'green',
    text: 'Доставка'
  },
  banquet: {
    text: 'Банкет',
    color: 'violet'
  },
  hall: {
    text: 'Зал',
    color: 'blue'
  }
}

export default orderTypes
