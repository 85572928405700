import BaseService from './BaseService'
import { httpClient } from '@/api/httpClient'
import { AxiosResponse } from 'axios'
import { Restaurant } from '@/models/entity/restaurant'
import UrlService from './UrlService'

export default class WaiterService extends BaseService {
  static async saveWaiter (payload: {
    firstName: string;
    lastName: string;
    middleName: string;
    phone: string;
    sRestaurants: any[];
  }) {
    const { firstName, lastName, middleName, phone, sRestaurants } = payload

    return httpClient.post('/protected/waiters', {
      first_name: firstName,
      last_name: lastName,
      phone: phone.replace(/[^\d]/g, ''),
      middle_name: middleName,
      restaurants: sRestaurants
    })
  }

  static async block (waiterId: string) {
    return httpClient.get(`/protected/waiters/${waiterId}/block`)
  }

  static async unblock (waiterId: string) {
    return httpClient.get(`/protected/waiters/${waiterId}/unblock`)
  }

  static async getRestaurants () {
    const { data } = await httpClient.get<never, AxiosResponse<Restaurant[]>>('/protected/waiters/restaurants')

    return data
  }

  static async updateWaiter (payload: {
    waiterId: string;
    firstName: string;
    lastName: string;
    middleName: string;
    phone: string;
    sRestaurants: any[];
  }) {
    const { firstName, lastName, middleName, phone, waiterId, sRestaurants } = payload
    return httpClient.post(`/protected/waiters/${waiterId}`, {
      first_name: firstName,
      last_name: lastName,
      phone: phone.replace(/[^\d]/g, ''),
      middle_name: middleName,
      restaurants: sRestaurants
    })
  }

  static async getWaiters (payload: { page: number, per_page: number }, payloadQ: { sorts: string, blocked_in?: boolean[], last_name_or_middle_name_or_phone_or_first_name_cont?: string }) {
    const filters = new URLSearchParams()
    filters.set('page', payload.page.toString())
    filters.set('per_page', payload.per_page.toString())

    const q = UrlService.getFiltersQuery(payloadQ)

    const data = await httpClient.get(`/protected/waiters?${filters}&${q}`)

    return data
  }

  static getWaitersRaitingRoute (payload: { page: number, per_page: number }, payloadQ: any) {
    const filters = new URLSearchParams()
    filters.set('page', payload.page.toString())
    filters.set('per_page', payload.per_page.toString())

    const q = UrlService.getFiltersQuery(payloadQ)

    return `/protected/waiters/analytics?${filters}&${q}`
  }

  static async getWaitersRating (route: any) {
    const data = await httpClient.get(route)

    return data
  }
}
