import React, { PureComponent } from 'react'
import AsyncSelect from 'react-select/async'
import { cloneDeep } from 'lodash'
import PropTypes from 'prop-types'

import { assembleSearchParams } from '../../utils'
import { AppSelectService } from '../../services'

class AppDropdown extends PureComponent {
  static defaultProps = {
    isAsync: true,
    sourceEndpoint: '',
    valueKey: 'id',
    queryKey: 'name',
    isEdit: false,
    options: [],
    placeholder: 'Ничего не выбрано',
    disabled: false,
    loading: false,
    isMulti: false,
    invalid: false,
    labelBuilder: () => {},
    onChange: () => {},
    onSelectedAll: () => {}
  }

  state = {
    /**
     * List of selected item in "async" mode.
     */
    asyncSelected: null,
    /**
     * Loaded options list in "async" mode.
     */
    asyncOptions: this.props.options,
    /**
     * Pagination state.
     */
    page: 1,
    /**
     * Search string to load filter options.
     */
    searchingTerm: '',
    /**
     * Local loading state.
     */
    isLoading: false
  }

  //
  // Lifecycle
  //

  componentDidMount () {
    this.setState({ asyncSelected: this.props.value })
  }

  //
  // Methods
  //

  handleAsyncValueChange = (value) => {
    this.setState({
      asyncSelected: value,
      asyncOptions: this.props.options,
      searchingTerm: '',
      page: 1
    })
    this.props.onChange(value)
  }

  loadAsyncOptions = async (searchingTerm = '') => {
    let newAsyncOptions = []
    let page = this.state.page

    if (searchingTerm.trim()) {
      page = 1
      this.setState({ page: 1 })
    }

    const { sourceEndpoint, valueKey, labelBuilder, queryKey } = this.props
    const queryParams = assembleSearchParams(
      searchingTerm,
      [ queryKey ],
      {}
    )

    try {
      this.setState({ isLoading: true })

      const options = await AppSelectService.getOptions(sourceEndpoint, valueKey, labelBuilder, queryParams, page)
      newAsyncOptions = !searchingTerm ? [ ...this.state.asyncOptions, ...options ] : options
    } finally {
      this.setState({ isLoading: false })
    }

    this.setState({
      searchingTerm,
      asyncOptions: newAsyncOptions
    })

    return newAsyncOptions
  }

  handleMenuScrollToBottom = () => {
    this.setState({ page: this.state.page + 1 }, () => {
      this.loadAsyncOptions(this.state.searchingTerm, true)
    })
  }

  renderAsyncSelect = () => {
    const {
      placeholder,
      disabled,
      loading,
      invalid,
      isMulti,
      value: defaultValue
    } = this.props
    const { asyncSelected, asyncOptions } = this.state
    const options = cloneDeep(asyncOptions)
    const customStyle = {
      valueContainer: provided => ({
        ...provided,
        cursor: 'text'
      }),
      control: (provided, { menuIsOpen }) => {
        let borderColor = menuIsOpen ? 'rgb(150, 200, 218) !important' : 'rgba(34, 36, 38, 0.15)'
        let color = '#000'
        let background = '#fff'

        if (invalid) {
          borderColor = '#e0b4b4'
          color = '#9f3a38'
          background = '#fff6f6'
        }

        return ({
          ...provided,
          borderColor,
          color,
          background,
          boxShadow: 'none',
          '&:hover': {
            borderColor: 'rgba(34, 36, 38, 0.35)'
          },
          '& > div:first-of-type': {
            maxHeight: '97px',
            overflowY: 'auto'
          }
        })
      },
      placeholder: provided => ({
        ...provided,
        color: 'rgba(191, 191, 191, 0.87)'
      }),
      menu: (provided) => ({
        ...provided,
        zIndex: '10000'
      }),
      option: provided => ({
        ...provided,
        cursor: 'pointer',
        zIndex: '10000',
        color: 'rgba(0, 0, 0, 0.95)',
        background: 'none',
        '&:hover,&:active': {
          background: 'rgba(0, 0, 0, 0.05)'
        }
      }),
      multiValue: provided => ({
        ...provided,
        border: '1px solid #e0e0e0'
      }),
      multiValueRemove: provided => ({
        ...provided,
        cursor: 'pointer'
      }),
      dropdownIndicator: provided => ({
        ...provided,
        cursor: 'pointer'
      })
    }

    return (
      <AsyncSelect
        className='app-dropdown'
        defaultOptions={options}
        defaultValue={defaultValue}
        options={asyncOptions}
        loadOptions={(inputValue) => this.loadAsyncOptions(inputValue, true)}
        styles={customStyle}
        placeholder={placeholder}
        isDisabled={disabled}
        isLoading={loading || this.state.isLoading}
        onChange={this.handleAsyncValueChange}
        value={options.find(option => option.value === asyncSelected)}
        loadingMessage={() => 'Идет загрузка'}
        isClearable={false}
        isMulti={isMulti}
        isSearchable
        noOptionsMessage={() => 'Ничего не найдено'}
        components={{
          IndicatorSeparator: () => null
        }}
        onMenuScrollToBottom={this.handleMenuScrollToBottom}
      />
    )
  }

  render () {
    if (this.props.isAsync) {
      return this.renderAsyncSelect()
    }
  }
}

AppDropdown.propTypes = {
  /**
   * The "true" value means you' moving form "manual" mode to "async" mode.
   * In this case all of fetching functionality will be handled
   * by this component.
   */
  isAsync: PropTypes.bool,
  /**
   * (Available in "Async" mode)
   * An API endpoint to loading options.
   */
  sourceEndpoint: PropTypes.string,
  /**
   * (Available in "Async" mode)
   * The key name that has to be `value` of selected item.
   */
  valueKey: PropTypes.string,
  /**
   * (Available in "Async" mode)
   * The label builder function.
   * @example
   * ```
   * <AppDropdown
   *  ...
   *  labelBuilder={item => `{item.firstName} {item.lastName}`}
   *  ...
   * />
   * ```
   */
  labelBuilder: PropTypes.func,
  /**
   * (Available in "Async" mode)
   * A key by what the query structure will be assembled.
   */
  queryKey: PropTypes.string,
  /**
   * Is on edit state.
   */
  isEdit: PropTypes.bool,
  /**
   * Select options list.
   */
  options: PropTypes.array,
  /**
   * Control placeholder.
   */
  placeholder: PropTypes.string,
  /**
   * Is on disabled state.
   */
  disabled: PropTypes.bool,
  /**
   * Is on loading state.
   */
  loading: PropTypes.bool,
  /**
   * On value changed event.
   */
  onChange: PropTypes.func,
  /**
   * Is invalid
   */
  invalid: PropTypes.bool
}

export default AppDropdown
