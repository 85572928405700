import React from 'react'
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Datetime from 'react-datetime'

import './styles.scss'
import 'react-datetime/css/react-datetime.css'

export default function AppDateTimePicker (props) {
  const {
    value,
    onChange,
    dateFormat = 'DD.MM.YYYY',
    timeFormat = 'HH:mm',
    placeholder,
    prefix,
    required = false,
    calendarIcon = true,
    ...rest
  } = props

  //
  // Render
  //

  const renderCalendarIcon = calendarIcon && (
    <div className='input-group-append'>
      <div className='input-group-text'>
        <FontAwesomeIcon icon={faCalendarAlt} />
      </div>
    </div>
  )

  return (
    <div className="app-date-time-picker">
      {prefix}
      <Datetime
        name="ended_at"
        dateFormat={dateFormat}
        timeFormat={timeFormat}
        inputProps={{ placeholder, required }}
        value={value}
        onChange={onChange}
        {...rest}
      />
      {renderCalendarIcon}
    </div>
  )
}
