import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Input } from 'semantic-ui-react'

import './styles.scss'

function QuickAnswersList (props) {
  const { onSelect = () => {}, isOpen = true, templates = [] } = props

  //
  // State
  //

  const [ searchingTerm, setSearchingTerm ] = useState('')
  const [ templatesToRender, setTemplatesToRender ] = useState([])

  //
  // Methods
  //

  const handleSelect = (templateId) => {
    const template = templates.find(template => template.id === templateId)

    if (template) {
      onSelect(template.text)
    } else {
      onSelect('')
    }
  }

  //
  // Effects
  //

  useEffect(() => {
    if (!isOpen) {
      setTimeout(() => {
        setSearchingTerm('')
      }, 500)
    }
  }, [ isOpen, templates ])

  useEffect(() => {
    if (searchingTerm.trim()) {
      const newTemplatesToRender = templates.filter(template =>
        template.title.toLowerCase().includes(searchingTerm.toLowerCase())
      )

      setTemplatesToRender(newTemplatesToRender)
    } else {
      setTemplatesToRender(templates)
    }
  }, [ searchingTerm, templates ])

  //
  // Render
  //

  const renderTemplates = templates.length > 0 && (
    <div>
      {templatesToRender.map(template => (
        <div
          key={template.id}
          className="quick-answers-list__template"
          onClick={() => handleSelect(template.id)}
        >
          <h4>{template.title}</h4>
          <p>{template.text}</p>
        </div>
      ))}
    </div>
  )

  return (
    <div className="quick-answers-list">
      <div className="quick-answers-list__header">
        <h3>Быстрые ответы</h3>
        <Input
          size="small"
          value={searchingTerm}
          placeholder='Поиск...'
          onChange={event => setSearchingTerm(event.target.value)}
        />
      </div>
      {renderTemplates}
    </div>
  )
}

QuickAnswersList.propTypes = {
  onSelect: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  templates: PropTypes.array.isRequired
}

export default QuickAnswersList
