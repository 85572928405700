import { useEffect, useRef } from 'react'

export default function useDidUpdateEffect (callback, deps = []) {
  const didMountRef = useRef(false)

  useEffect(() => {
    if (didMountRef.current) {
      callback()
    } else {
      didMountRef.current = true
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps)
}
