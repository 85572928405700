import { AxiosResponse } from 'axios'

import BaseService from '../BaseService'
import { API_URL, httpClient } from '@/api/httpClient'
import { ConnectIikoFormPayload, RestaurantApiPayload } from '.'
import { Restaurant } from '@/models/entity/restaurant'
import { City } from '@/models/entity/city/City'
import UrlService from '../UrlService'

export default class RestaurantService extends BaseService {
  static async loadInfo (rest_id: string) {
    const { data } = await httpClient.get(`/public/restaurants/${rest_id}`)

    return data
  }

  static async list (payload?: { city_ids?: string[], page?: number, per_page?: number }, payloadQ?: { sorts: string, name_cont?: string, address_cont?: string, phone_cont?: string }) {
    const filters = new URLSearchParams()
    payload?.city_ids && payload.city_ids.forEach((id) => {
      filters.append('filters[city_ids][]', id)
    })

    payload?.page && filters.set('page', payload.page.toString())
    payload?.per_page && filters.set('per_page', payload.per_page.toString())

    const q = payloadQ && UrlService.getFiltersQuery(payloadQ)

    let url = `${API_URL}/protected/restaurants?`

    if (filters) url = `${API_URL}/protected/restaurants?${filters}`

    if (q) url = `${API_URL}/protected/restaurants?${filters}&${q}`

    const data = await httpClient.get<never, AxiosResponse<Restaurant[]>>(url)

    return data
  }

  static async update (rest_id: string, data: RestaurantApiPayload) {
    return httpClient.put(`/protected/restaurants/${rest_id}`, data)
  }

  static async create (data: RestaurantApiPayload) {
    return httpClient.post('/protected/restaurants', data)
  }

  static async connectIiko (id: string, data: ConnectIikoFormPayload) {
    return httpClient.put(`/protected/restaurants/${id}/iiko`, data)
  }

  static async delete (id: string) {
    return httpClient.delete(`/protected/restaurants/${id}`)
  }

  static async getCities (chatbotGroupIds: string[] = []) {
    const filters = new URLSearchParams()
    chatbotGroupIds.forEach((id) => {
      filters.append('filters[chatbot_group_ids][]', id)
    })

    const { data } = await httpClient.get<never, AxiosResponse<City[]>>(`/protected/cities?${filters}`)

    return data
  }
}
