import { camelize } from '@/utils/formatters'

/**
 * The common model for validation errors.
 */
export default class ValidationError extends Error {
  errors

  constructor (message, errors) {
    super(message)
    this.errors = errors
  }
}

/**
 * HTTP 400 - Returns information about incorrect fields.
 * Map: field => array of related error messages.
 */
export class ServerValidationError extends Error {
   validationErrors;

   constructor ({ message, errors, status }) {
     super(message)
     this.name = 'ServerValidationError'
     this.status = status
     this.validationErrors = errors ?? []
   }
}

/**
 * HTTP 403 Forbidden.
 */
export class ForbiddenError extends Error {
  constructor ({ message, status }) {
    super(message)
    this.name = 'ForbiddenError'
    this.status = status
  }
}

/**
 * HTTP 404 Not Found.
 */
export class NotFoundError extends Error {
  constructor ({ message, status }) {
    super(message)
    this.name = 'NotFoundError'
    this.status = status
  }
}

/**
 * HTTP 503 - Typically, when application is updated, restarted, etc.
 */
export class ServerUnavailable extends Error {
  constructor () {
    super('Server is temporary unavailable, try in a few minutes')
    this.name = 'ServerUnavailable'
  }
}

/**
 * HTTP 500 - Most probably, unhandled error in server code.
 */
export class InternalServerError extends Error {
  constructor (response) {
    const message = response.data?.message ? response.data.message : response.data.error
    super(message)
    this.name = 'InternalServerError'
  }
}

/**
 * Server code was executing too long, and Nginx returned error without waiting for result.
 */
export class GatewayTimeout extends Error {
  constructor () {
    super('Server operation timed out')
    this.name = 'GatewayTimeout'
  }
}

/**
 * For all other cases, when server replies with error.
 */
export class ServerError extends InternalServerError {
  data;

  constructor (response) {
    super(response)
    this.name = 'ServerError'
    this.data = response.data
    this.status = response.status
  }
}

/**
 * HTTP Server reply was not received.
 * Probably no network connection or CORS error, AdBlock, etc.
 */
export class NetworkError extends Error {
  constructor (message) {
    super(message || 'Check your network connection')
    this.name = 'NetworkError'
  }
}

/**
 * Convert the Server validation error to the validation error.
 */
export function getValidationError (error) {
  const validationErrors = error.validationErrors.reduce((acc, err, index) => {
    if (typeof err === 'object') {
      return {
        ...acc,
        [camelize(err.field)]: err.messages
      }
    } else if (typeof err === 'string') {
      return {
        ...acc,
        [index]: err
      }
    }

    return acc
  }, {})

  return new ValidationError(error.message, validationErrors)
}
