import Alert from 'react-s-alert'

export default class NotificationsService {
  static error (error: string, timeout = 10000) {
    Alert.error(error, { timeout })
  }

  static success (success: string) {
    Alert.success(success)
  }

  static warning (warning: string) {
    Alert.warning(warning)
  }

  static info (info: string) {
    Alert.info(info)
  }
}
