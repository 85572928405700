import { OrdersItemsAssignedModifiers, OrdersItems, OrdersItemsGuest, OrdersItemsKitchen, OrdersItemsProductCategory, OrdersItemsProduct, OrdersItemsWaiter } from '@/models/entity/orders/OrdersItems'
import { OrdersItemsMappedAssignedModifiers, OrdersItemsMapped, OrdersItemsMappedGuest, OrdersItemsMappedKitchen, OrdersItemsMappedProductCategory, OrdersItemsMappedProduct, OrdersItemsMappedWaiter } from '@/models/entity/orders/OrdersItemsMapped'

const mapDtoToModifier = (dto: OrdersItemsAssignedModifiers): OrdersItemsMappedAssignedModifiers => ({
  amount: dto['Amount'],
  amountIndependentOfParentAmount: dto['AmountIndependentOfParentAmount'],
  cost: dto['Cost'],
  deleted: dto['Deleted'],
  deletionMethod: dto['DeletionMethod'],
  id: dto['Id'],
  isSeparate: dto['IsSeparate'],
  price: dto['Price'],
  pricePredefined: dto['PricePredefined'],
  productGroup: dto['ProductGroup'],
  resultSum: dto['ResultSum'],
  taxPercent: dto['TaxPercent'],
  product: dto['Product'] && mapDtoToIikoProduct(dto['Product'])
})

export const mapDtoToIikoOrderItem = (dto: OrdersItems): OrdersItemsMapped => {
  const waiter = dto['Waiter'] && mapDtoToIikoWaiter(dto['Waiter'])
  const guest = dto['Guest'] && mapDtoToIikoGuest(dto['Guest'])
  const kitchen = dto['Kitchen'] && mapDtoToIikoKitchen(dto['Kitchen'])
  const product = dto['Product'] && mapDtoToIikoProduct(dto['Product'])

  return {
    id: dto['Id'],
    cost: dto['Cost'],
    size: dto['Size'],
    price: dto['Price'],
    amount: dto['Amount'],
    course: dto['Course'],
    status: dto['Status'],
    comment: dto['Comment'],
    deleted: dto['Deleted'],
    openPrice: dto['OpenPrice'],
    printTime: dto['PrintTime'],
    resultSum: dto['ResultSum'],
    serveTime: dto['ServeTime'],
    taxPercent: dto['TaxPercent'],
    cookingTime: dto['CookingTime'],
    deletionMethod: dto['DeletionMethod'],
    pricePredefined: dto['PricePredefined'],
    cookingStartTime: dto['CookingStartTime'],
    assignedModifiers: dto['AssignedModifiers'] && dto['AssignedModifiers'].map(mapDtoToModifier),
    cookingFinishTime: dto['CookingFinishTime'],
    availableGroupModifiers: dto['AvailableGroupModifiers'],
    availableSimpleModifiers: dto['AvailableSimpleModifiers'],
    waiter,
    guest,
    kitchen,
    product
  }
}

const mapDtoToIikoGuest = (dto: OrdersItemsGuest): OrdersItemsMappedGuest => ({
  id: dto['Id'],
  name: dto['Name'],
  rank: dto['Rank']
})

const mapDtoToIikoKitchen = (dto: OrdersItemsKitchen): OrdersItemsMappedKitchen => ({
  id: dto['Id'],
  name: dto['Name'],
  tables: dto['Tables'] && dto['Tables'].map(mapDtoToIikoTable),
  defaultOrderType: dto['DefaultOrderType'],
  kitchenCoursesEnabled: dto['KitchenCoursesEnabled'],
  orderItemCommentsEnabled: dto['OrderItemCommentsEnabled'],
  orderItemsGroupingEnabled: dto['OrderItemsGroupingEnabled']
})

const mapDtoToIikoTable = (dto: any) => ({
  id: dto['Id'],
  number: dto['Number']
})

const mapDtoToIikoProduct = (dto: OrdersItemsProduct): OrdersItemsMappedProduct => ({
  id: dto['Id'],
  name: dto['Name'],
  type: dto['Type'],
  price: dto['Price'] ? dto['Price'] : 0,
  scale: dto['Scale'],
  number: dto['Number'],
  category: dto['Category'] && mapDtoToProductIikoCategory(dto['Category']),
  fastCode: dto['FastCode'],
  fullName: dto['FullName'],
  isActive: dto['IsActive'],
  template: dto['Template'],
  fontColor: dto['FontColor'],
  menuIndex: dto['MenuIndex'],
  taxPercent: dto['TaxPercent'],
  description: dto['Description'],
  foreignName: dto['ForeignName'],
  kitchenName: dto['KitchenName'],
  foodValueFat: dto['FoodValueFat'],
  hasMenuImage: dto['HasMenuImage'],
  rateSchedule: dto['RateSchedule'],
  expirationTime: dto['ExpirationTime'],
  backgroundColor: dto['BackgroundColor'],
  canSetOpenPrice: dto['CanSetOpenPrice'],
  foodValueProtein: dto['FoodValueProtein'],
  measuringUnitName: dto['MeasuringUnitName'],
  descriptionForeign: dto['DescriptionForeign'],
  foodValueCaloricity: dto['FoodValueCaloricity'],
  foodValueCarbohydrate: dto['FoodValueCarbohydrate']
})

const mapDtoToProductIikoCategory = (dto: OrdersItemsProductCategory): OrdersItemsMappedProductCategory => ({
  id: dto['Id'],
  name: dto['Name']
})

const mapDtoToIikoWaiter = (dto: OrdersItemsWaiter): OrdersItemsMappedWaiter => ({
  id: dto['Id'],
  card: dto['Card'],
  name: dto['Name'],
  phone: dto['CellPhone'],
  isSessionOpened: dto['IsSessionOpen']
})
