import { SemanticCOLORS } from 'semantic-ui-react/dist/commonjs/generic'

type Constant = {
  unaccepted: {
    color: SemanticCOLORS,
    text: string,
    value: string
  },
  new_order: {
    color: SemanticCOLORS,
    text: string,
    value: string
  },
  prepared: {
    color: SemanticCOLORS,
    text: string,
    value: string
  },
  bill: {
    color: SemanticCOLORS,
    text: string,
    value: string
  },
  closed: {
    color: SemanticCOLORS,
    text: string,
    value: string
  },
  deleted: {
    color: SemanticCOLORS,
    text: string,
    value: string
  },
  pending: {
    color: SemanticCOLORS,
    text: string,
    value: string
  },
  transit: {
    color: SemanticCOLORS,
    text: string,
    value: string
  },
  delivered: {
    color: SemanticCOLORS,
    text: string,
    value: string
  },
  cooking: {
    color: SemanticCOLORS,
    text: string,
    value: string
  },
  declined: {
    color: SemanticCOLORS,
    text: string,
    value: string
  }
}

const orderStatuses: Constant = {
  unaccepted: {
    color: 'red',
    text: 'Отклонен',
    value: 'unaccepted'
  },
  new_order: {
    color: 'green',
    text: 'Новый',
    value: 'new_order'
  },
  prepared: {
    color: 'green',
    text: 'Приготовился',
    value: 'prepared'
  },
  bill: {
    color: 'blue',
    text: 'Пречек',
    value: 'bill'
  },
  closed: {
    color: 'violet',
    text: 'Закрыт',
    value: 'closed'
  },
  deleted: {
    color: 'red',
    text: 'Удален',
    value: 'deleted'
  },
  pending: {
    color: 'brown',
    text: 'Ожидает',
    value: 'pending'
  },
  transit: {
    color: 'pink',
    text: 'В пути',
    value: 'transit'
  },
  delivered: {
    color: 'green',
    text: 'Доставлен',
    value: 'delivered'
  },
  cooking: {
    color: 'grey',
    text: 'Готовится',
    value: 'cooking'
  },
  declined: {
    color: 'red',
    text: 'Отменен',
    value: 'declined'
  }
}

export default orderStatuses
