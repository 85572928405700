import { MessagePayload } from '@/models/entity/message/Message'
import React, { PureComponent } from 'react'
import { PhotoProvider, PhotoConsumer } from 'react-photo-view'

import 'react-photo-view/dist/index.css'
import './ChatMessage.css'

type Props = {
  image: string | null,
  message: MessagePayload | null
}

class ChatMessage extends PureComponent<Props> {
  renderImage = () => {
    const { image } = this.props

    return image && (
      <React.Fragment>
        <PhotoProvider>
          <PhotoConsumer src={image}>
            <img
              src={image}
              alt=""
              width="200px"
              height="auto"
              style={{ cursor: 'pointer' }}
            />
          </PhotoConsumer>
        </PhotoProvider>
        <br></br>
      </React.Fragment>
    )
  }

  render () {
    let message = null
    if (this.props.message) {
      message = this.props.message.message
    }

    return (
      <p>
        {this.renderImage()}
        {message}
      </p>
    )
  }
}

export default ChatMessage
