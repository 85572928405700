import React, { Component } from 'react'
import { Checkbox, Table, Input } from 'semantic-ui-react'

export default class WorkingDays extends Component {
  constructor (props) {
    super(props)

    this.state = {
      collapsedComment: false,
      type: null,
      verify: false,
      operating_mode: {
        1: '',
        2: '',
        3: '',
        4: '',
        5: '',
        6: '',
        7: ''
      }
    }

    this.DAYS = {
      1: 'ПН',
      2: 'ВТ',
      3: 'СР',
      4: 'ЧТ',
      5: 'ПТ',
      6: 'СБ',
      7: 'ВС'
    }
  }

  static defaultProps = {
    onChange: () => {},
    onValidate: () => {},
    fluid: false,
    disabled: false,
    operating_mode: {},
    label: '',
    required: true,
    verify: false
  }

  componentDidMount () {
    if (typeof (this.props.operating_mode) === 'object') {
      this.setState({ type: 'object' })

      if (!Object.keys(this.props.operating_mode).length) {
        this.setState(prevState => {
          const operating_mode = Object.assign({ ...this.props.operating_mode }, prevState.operating_mode)

          return { operating_mode }
        })
      } else {
        this.setState({
          operating_mode: this.props.operating_mode,
          verify: this.props.verify
        })
      }
    }
  }

  handleInputChange = ({ target: { name, value } }) => {
    this.setState(prevState => {
      const operating_mode = Object.assign({}, prevState.operating_mode)
      operating_mode[name] = value

      this.props.onChange(operating_mode)

      return { operating_mode }
    })
  }

  renderCheckbox (key, label) {
    return (
      <Checkbox
        name={key}
        toggle
        label={label}
        color='green'
        disabled={this.props.disabled}
        onChange={(target, { name, checked }) => {
          if (checked) {
            this.setState(prevState => {
              const operating_mode = Object.assign({}, prevState.operating_mode)
              operating_mode[name] = label

              this.props.onChange(operating_mode)

              return { operating_mode }
            })
          } else {
            this.setState(prevState => {
              const operating_mode = Object.assign({}, prevState.operating_mode)
              operating_mode[name] = ''

              this.props.onChange(operating_mode)

              return { operating_mode }
            })
          }
        }}
        checked={this.state.operating_mode[key] === label}
      />
    )
  }

  renderStatusFieldT (item) {
    const { verify } = this.props
    const required = this.props.required

    if (required && verify && !item) {
      return 'error'
    } else {
      return ''
    }
  }

  renderInput (key) {
    const { operating_mode } = this.state
    return (
      <Input
        name={key}
        value={operating_mode[key]}
        onChange={this.handleInputChange}
        placeholder='8:00-20:00'
        disabled={this.props.disabled}
        required={this.props.required}
      />
    )
  }

  renderRows () {
    const { type, operating_mode } = this.state
    switch (type) {
    case 'object':
      return Object.keys(operating_mode).map(k => {
        return (
          <Table.Row className={this.renderStatusFieldT(operating_mode[k])} >
            <Table.Cell>{this.DAYS[k]}</Table.Cell>
            <Table.Cell>{this.renderInput(k)}</Table.Cell>
            <Table.Cell>{this.renderCheckbox(k, 'Круглосуточно')}</Table.Cell>
            <Table.Cell>{this.renderCheckbox(k, 'Выходной')}</Table.Cell>
          </Table.Row>
        )
      })
    default:
      break
    }
  }

  renderLabel (label) {
    return (<label>{label}</label>)
  }

  render () {
    const r = [
      this.renderLabel(this.props.label),
      <Table compact>
        <Table.Body>
          {this.renderRows()}
        </Table.Body>
      </Table>
    ]

    return r
  }
}
