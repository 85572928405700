/**
 * Assemble search params to invoke API request.
 * @param {string} value - Value to zip in query structure.
 * @param {string[]} searchFields - Fields that need to be searched by `value`.
 * @param {Object} q - Extra query object.
 * @returns {Object} Result query structure.
 * @example
 * ```
 *  const query = assembleSearchParams(
 *    'Value to search',
 *    ['param_1', 'param_2', 'param_3'],
 *    { creation_step_eq: 'done' }
 *  )
  ```
 */
export default (value, searchFields, q = {}) => {
  let searchKey = ''

  for (let i = 0; i <= searchFields.length; i++) {
    if (i === searchFields.length) break

    if (i === searchFields.length - 1) {
      searchKey += `${searchFields[i]}`
      continue
    }

    searchKey += `${searchFields[i]}_or_`
  }

  q[`${searchKey}_cont`] = value

  return q
}
