import { SemanticCOLORS } from 'semantic-ui-react'

export enum ValueTypes {
  planned = 'planned',
  active = 'active',
  finished = 'finished',
  stopped = 'stopped',
  finishedByUser = 'finished_by_user',
}

type ConfigItem = {
  value: ValueTypes;
  name: string;
  queryParamValue: number;
  colorName: SemanticCOLORS;
};

const config: Record<string, ConfigItem> = {
  planned: {
    value: ValueTypes.planned,
    name: 'В плане',
    queryParamValue: 0,
    colorName: 'blue'
  },
  active: {
    value: ValueTypes.active,
    name: 'Активная',
    queryParamValue: 1,
    colorName: 'green'
  },
  finished: {
    value: ValueTypes.finished,
    name: 'Завершилась',
    queryParamValue: 2,
    colorName: 'purple'
  },
  stopped: {
    value: ValueTypes.stopped,
    name: 'Остановлена',
    queryParamValue: 3,
    colorName: 'red'
  },
  finishedByUser: {
    value: ValueTypes.finishedByUser,
    name: 'Завершена',
    queryParamValue: 4,
    colorName: 'purple'
  }
}

export default config
