import React from 'react'
import { Popup } from 'semantic-ui-react'
import PropTypes from 'prop-types'

const BASE_ENTER_DELAY = 800
const BASE_LEAVE_DELAY = 0

export default function AppTooltip (props) {
  const {
    children,
    content,
    enterDelay = BASE_ENTER_DELAY,
    leaveDelay = BASE_LEAVE_DELAY
  } = props

  return (
    <Popup
      content={content}
      mouseEnterDelay={enterDelay}
      mouseLeaveDelay={leaveDelay}
      on='hover'
      trigger={children}
    />
  )
}

AppTooltip.propTypes = {
  children: PropTypes.element.isRequired,
  content: PropTypes.oneOfType([ PropTypes.element, PropTypes.string ]),
  enterDelay: PropTypes.number,
  leaveDelay: PropTypes.number
}
