export default {
  sms: {
    value: 'sms',
    text: 'SMS'
  },
  whatsApp: {
    value: 'whatsApp',
    text: 'WhatsApp'
  },
  whatsAppAndSms:
  {
    value: 'whatsAppAndSms',
    text: 'WhatsApp + SMS'
  }
}
